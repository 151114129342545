<div
  fxFlex="100%"
  fxLayout="column"
  fxLayoutAlign="center stretch"
  class="file__modal"
  [ngClass]="{ 'framed': !!(fileDialog.okButton && fileDialog.noButton) }"
>
  <div class="frame" fxFlex="100%">
    <app-file-render
      class="file-render"
      [fileData]="fileDialog.fileData"
    ></app-file-render>
    <div class="border-color-primary border border--top border--left"></div>
    <div class="border-color-primary border border--top border--right"></div>
    <div class="border-color-primary border border--bottom border--left"></div>
    <div class="border-color-primary border border--bottom border--right"></div>
  </div>
  <div class="rules">
    <p>Antes de continuar verifique se:</p>
    <ul class="rules__list">
      <li class="rules__item"><strong>A foto não está borrada</strong></li>
      <li class="rules__item"><strong>O documento todo está dentro das bordas</strong></li>
    </ul>
  </div>
  <div *ngIf="!!fileDialog.okButton || !!fileDialog.noButton" class="container" fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="16px">
    <button
      mat-stroked-button
      class="closecare-button primary-border"
      fxFlex="1 1 100%"
      color="primary"
      (click)="redo()"
      [attr.aria-label]="fileDialog.noButton"
    >
      {{fileDialog.noButton}}
    </button>
    <button
      mat-raised-button
      cdkFocusInitial
      data-test="confirmation"
      class="closecare-button primary-bg"
      fxFlex="1 1 100%"
      *ngIf="fileDialog.okButton"
      (click)="confirm()"
      [attr.aria-label]="fileDialog.okButton"
    >
      {{fileDialog.okButton}}
    </button>
  </div>
</div>
<button
  mat-mini-fab
  class="close"
  color="primary"
  aria-label="Fechar"
  *ngIf="!fileDialog.okButton && !fileDialog.noButton"
  (click)="redo()"
>
  <mat-icon>close</mat-icon>
</button>

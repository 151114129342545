import {Component, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent {
  @Output() public edit: EventEmitter<any> = new EventEmitter<any>();
}

import {FileData} from 'src/app/models/file.model';
import {ItemPicker} from '../../../../components/item-picker/item-picker.model';

export enum AnswerType {
  text = 'text',
  code = 'code',
  number = 'number',
  email = 'email',
  phone = 'phone',
  icd = 'icd',
  date = 'date',
  time = 'time',
  picker = 'picker',
  picture = 'picture',
  file = 'file',
  bottomSheet = 'bottomSheet',
  send = 'send'
}

export interface Button {
  value?: string;
  label: string;
  negative?: boolean;
  disabled?: boolean;
  forceCancel?: boolean;
  correction?: string;
}

export enum AnswerPickers {
  doses = 'doses',
  vaccineTypes = 'vaccine_types',
  professionalTitle = 'professional_title',
  states = 'states',
  docTypes = 'doc_types'
}

export interface BottomSheet {
  fields: Array<Answer>;
  description?: string;
  button?: Button;
}

export interface LimitDate {
  amount: number;
  period: string;
  key?: string;
}
export interface Reference {
  key?: string;
  type?: string;
  dependence?: string;
}

export interface Condition {
  key: string;
  value: any;
}

export interface Prefix {
  conditions: Array<Condition>;
  fill: any;
}
export interface Answer {
  type?: string;
  placeholder?: string;
  required?: boolean;
  button?: Button;
  buttons?: Array<Button>;
  vertical?: boolean;
  cancel?: string;
  title?: string;
  picker?: string;
  bottomSheet?: BottomSheet;
  minTime?: LimitDate;
  maxTime?: LimitDate;
  reference?: Reference;
  prefix?: Prefix;
}

export enum ToLoad {
  professionals = 'professionals',
  icds = 'icds',
  docTypes = 'docTypes',
  vaccineTypes = 'vaccineTypes',
  profissionalTypes = 'professionalTypes'
}

export enum Branch {
  basic = 'basic',
  complete = 'complete',
  noAllowancedVaccine = 'noAllowancedVaccine',
  dailyAllowancedVaccine = 'dailyAllowancedVaccine',
  timeAllowancedVaccine = 'timeAllowancedVaccine',
}

export type FlowType = Branch.basic | Branch.complete | Branch.noAllowancedVaccine | Branch.dailyAllowancedVaccine | Branch.timeAllowancedVaccine;

export interface Script {
  text?: string;
  answer?: Answer;
  warning?: boolean;
  load?: {[key: string]: string};
  image?: boolean;
  branch?: boolean;
  onCancel?: Array<string>;
  priority?: boolean;
  nexts?: {
    [key: string]: string;
  };
}

export enum From {
  bot = 'bot',
  user = 'user'
}

export interface Message {
  from: From;
  body: string;
  replacers?: Replacers;
  scriptId?: string;
  edition?: boolean;
  warning?: boolean;
  image?: boolean;
  fileData?: FileData;
  breakStrong?: boolean;
}

export enum Period {
  hours = 'hours',
  days = 'days',
  years = 'years'
}

export interface Picker {
  value: string;
  reference: string;
}

export interface Send {
  value?: string | File[];
  reference?: string;
}

export interface Answered {
  label?: string;
  send?: Send | Array<Send>;
  cancel?: Array<string>;
  bottomSheet?: string;
  adjusted?: boolean;
  file?: boolean;
}

export interface Update {
  valid: boolean;
  answered?: Answered;
  layout?: boolean;
  value?: string;
}

export interface TimeData {
  answer: Answer;
  dependence: string;
}

export interface BottomSheetData {
  bottomSheet: BottomSheet;
  replacers?: Replacers;
  states?: Array<ItemPicker>;
}

export enum BottomSheetTypes {
  updateProfessionalData = 'updateProfessionalData',
  fileType = 'fileType',

}

export interface State {
  id: string;
  name: string;
}

export enum Replacer {
  phone = 'phone',
  email = 'email',
  period_date = 'period_date',
  invalid_icd = 'invalid_icd',
  professional_type = 'professional_type',
  professional_title = 'professional_title',
  professional_id = 'professional_id',
  professional_state = 'professional_state',
  professional_name = 'professional_name',
  allowance_days = 'allowance_days',
  allowance_period = 'allowance_period',
  allowance_hours = 'allowance_hours'
}

export interface Replacers {
  [key: string]: string;
}

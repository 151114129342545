import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PdfViewerModule} from 'ng2-pdf-viewer';

import {SharedModule} from '../../shared/shared.module';
import {SpinnerModule} from '../../components/spinner/spinner.module';

import {FileRenderComponent} from './file-render.component';

@NgModule({
  imports: [
    CommonModule,
    PdfViewerModule,
    SharedModule,
    SpinnerModule
  ],
  declarations: [
    FileRenderComponent
  ],
  exports: [
    FileRenderComponent
  ]
})

export class FileRenderModule { }

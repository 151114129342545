import {Component, Input, Output, EventEmitter} from '@angular/core';
import {ItemPicker} from '../../../../../components/item-picker/item-picker.model';
import {AnswerType, Answered, Answer, FlowType} from '../../../shared/models/chat.model';

@Component({
  selector: 'app-answer-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss']
})

export class ButtonsComponent {
  public answerType: any = AnswerType;

  @Input() public answer: Answer;
  @Input() public dependence: string;
  @Input() public flowType: FlowType;
  @Input() public allowanceHours: number;
  @Input() public pickers: Array<ItemPicker>;
  @Input() public cancel: Array<string>;
  @Output() public sendAnswer: EventEmitter<Answered> = new EventEmitter<Answered>();

  public get common(): boolean {
    return (
      this.answer.type !== AnswerType.date &&
      this.answer.type !== AnswerType.time &&
      this.answer.type !== AnswerType.picker
    );
  }
}

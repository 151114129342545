import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {SickNoteService} from '../services/sick-note.service';

@Injectable({
  providedIn: 'root'
})
export class FileExistsGuard implements CanActivate {
  constructor(private router: Router,
              private sickNoteService: SickNoteService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree {
    if (!this.sickNoteService.fileExists()) {
      return this.router.createUrlTree(['/']);
    }
    return true;
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Document } from '../models/documents';
import {
    ApiService, API_DOCUMENT_CHECK_IF_IS_PROCESSING, API_DOCUMENT_CREATE, API_DOCUMENT_GET_BY_SHORT_ID
} from './api.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(private api: ApiService) {}

  createDocument(data): Observable<Document> {
    return this.api.http.post<Document>(API_DOCUMENT_CREATE, data);
  }

  getDocumentByShortId(shortId: string): Observable<Document> {
    return this.api.http.get<Document>(API_DOCUMENT_GET_BY_SHORT_ID(shortId));
  }

  checkIfDocumentIsProcessing(shortId: string): Observable<{ processing: boolean; status: string }> {
    return this.api.http.get<{ processing: boolean; status: string }>(
      API_DOCUMENT_CHECK_IF_IS_PROCESSING(shortId)
    );
  }
}

import {NgModule} from '@angular/core';
import {SickNoteComponent} from './sick-note.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {SharedModule} from '../../shared/shared.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatNativeDateModule} from '@angular/material/core';
import {LoadingModule} from '../../components/loading/loading.module';
import {MatButtonModule} from '@angular/material/button';
import {MaskDateModule} from '../../directives/mask-date/mask-date.module';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    SickNoteComponent
  ],
  imports: [
    SharedModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    LoadingModule,
    MatButtonModule,
    MaskDateModule,
    ReactiveFormsModule
  ]
})
export class SickNoteModule { }

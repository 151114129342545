import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';
import {formatISO, addDays, addYears} from 'date-fns';
import {Answer, Answered, LimitDate, Period, Send, FlowType, Branch} from '../../../../shared/models/chat.model';

@Component({
  selector: 'app-button-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})

export class DateComponent implements OnInit {
  public minTime: Date;
  public maxTime: Date;
  private today = new Date(new Date().setHours(0, 0, 0, 0));
  public picker: FormControl = new FormControl(this.today, Validators.required);

  @Input() public answer: Answer;
  @Input() public flowType: FlowType;
  @Input() public allowanceHours: number;
  @Output() private answered: EventEmitter<Answered> = new EventEmitter<Answered>();

  @ViewChild('datepicker') public datepicker: MatDatepicker<Date>;

  ngOnInit(): void {
    this.minTime = this.setLimitDate(this.picker.value, this.answer?.minTime);
    this.maxTime = this.setLimitDate(this.picker.value, this.answer?.maxTime);

    this.picker.valueChanges
      .subscribe((value: Date) => setTimeout(() => this.setAnswer(value)));
  }

  private setLimitDate(date: Date, limitDate: LimitDate): Date {
    if (limitDate?.period === Period.days) {
      date = addDays(date, limitDate.amount);
    }

    if (limitDate?.period === Period.years) {
      date = addYears(date, limitDate.amount);
    }

    return date;
  }

  private setAnswer(value: Date): void {
    if (value) {
      this.answered.emit(this.buildAnswer(value));
    }
  }

  private buildAnswer(value: Date): Answered {
    let send: Send | Array<Send> = {
      value: formatISO(value),
      reference: this.answer.reference.key
    };

    if (this.flowType === Branch.noAllowancedVaccine) {
      send = [send].concat({
        value: send.value as string,
        reference: 'expiration_date',
      });
    } else if (this.flowType === Branch.dailyAllowancedVaccine) {
      const allowanceDays = Math.trunc(this.allowanceHours / 24);

      send = [send].concat({
        value: formatISO(addDays(new Date(send.value as string), allowanceDays - 1)),
        reference: 'expiration_date',
      });
    }

    return {
      send,
      label: value.toLocaleDateString('pt-Br')
    };
  }
}

<mat-form-field appearance="standard">
  <mat-label>Data do atestado</mat-label>
  <input matInput [matDatepicker]="picker" required [formControl]="appointmentDate"
         autocomplete="off" inputmode="numeric" appMaskDate [min]="minDate" [max]="maxDate">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker touchUi #picker startView="month" [startAt]="today"></mat-datepicker>
  <mat-error *ngIf="appointmentDate.invalid">Data inválida</mat-error>
</mat-form-field>

<button mat-raised-button class="closecare-button primary-bg" (click)="next()"
        [disabled]="!this.appointmentDate.valid" aria-label="Próximo">
        Próximo
</button>
<app-loading *ngIf="loading"></app-loading>

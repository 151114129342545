import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    DynamicFormRequest,
    DynamicFormResponse,
    FindByCategoryIdAndTypeIdResponse
} from '../pages/dynamic-form/entities/dynamic-form.entity';
import { ApiService, API_DYNAMIC_FORMS_FIND_BY_CATEGORY_ID_AND_TYPE_ID, API_DYNAMIC_FORMS_NEXT_STATE } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicFormService {
  constructor(private api: ApiService) {}

  findByCategoryIdAndTypeId(categoryId: number, typeId: number): Observable<FindByCategoryIdAndTypeIdResponse> {
    return this.api.http.get<FindByCategoryIdAndTypeIdResponse>(API_DYNAMIC_FORMS_FIND_BY_CATEGORY_ID_AND_TYPE_ID(categoryId, typeId));
  }

  getNextQuestion(id: string, answers: DynamicFormRequest) {
    return this.api.http.post<DynamicFormResponse>(
      API_DYNAMIC_FORMS_NEXT_STATE(id),
      {
        answers,
      }
    );
  }
}

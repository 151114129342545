<button mat-button data-test="buttons-date" class="chat__button closecare-button primary-bg" (click)="datepicker.open()"
        [attr.aria-label]="answer?.button.label">{{answer?.button.label}}</button>
<mat-datepicker touchUi startView="month" #datepicker>
    <mat-datepicker-actions>
        <button mat-button matDatepickerApply color="primary" aria-label="Confirmar">CONFIRMAR</button>
    </mat-datepicker-actions>
</mat-datepicker>
<input
    class="input__date"
    [min]="minTime"
    [max]="maxTime"
    [matDatepicker]="datepicker"
    [formControl]="picker"
>

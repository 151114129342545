<div class="document-selection-container">
    <div class="p-32">
        <div class="document-categories-title font-weight-400 mb-32">
            Selecione a categoria do documento
        </div>
    
        <ng-container *ngFor="let category of documentTypes.categories">
            <button mat-button (click)="selectCategory(category)" class="document-category-button" aria-label="">
                <span class="font-size-20 font-weight-700">{{ category.label }}</span>
                <mat-icon color="primary" fontSet="material-icons-outlined">arrow_forward_ios</mat-icon>
                <div class="type-list font-size-14 font-weight-400 pb-16">{{ category.types | typeListToString }}</div>
            </button>
        </ng-container>
    </div>
</div>

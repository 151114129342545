<input
    required
    matInput
    data-test="input-number"
    class="input__field"
    autocomplete="off"
    inputmode="numeric"
    type="number"
    name="number"
    [imask]="numberMask"
    [placeholder]="placeholder"
    [formControl]="number"
>
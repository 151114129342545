<button
  mat-button
  data-test="buttons-button"
  class="chat__button closecare-button primary-bg"
  [disabled]="disabled"
  [ngClass]="{'chat__button--nevative': button?.negative}"
  (click)="setAnswer(button.value)"
  [attr.aria-label]="button?.label"
>
  {{button?.label}}
</button>

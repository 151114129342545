import {Component, Inject, ChangeDetectorRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {setHours, setMinutes, addDays, differenceInHours} from 'date-fns';
import {TimeData, Update} from '../../../../../shared/models/chat.model';

@Component({
  selector: 'app-dialog-time',
  templateUrl: './dialog-time.component.html',
  styleUrls: ['./dialog-time.component.scss']
})
export class DialogTimeComponent {
  public valid: boolean;
  public value: string;
  public reset = '00:00';
  public date: string;
  public expirationDate: Date | string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialogTimeComponent>,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.date = (new Date(this.expirationDate || this.data.dependence)).toLocaleDateString('pt-BR');
  }

  public static open(dialog: MatDialog, data: TimeData): MatDialogRef<DialogTimeComponent> {
    return dialog.open(DialogTimeComponent, {
      panelClass: 'dialog-hour',
      data
    });
  }

  public updated(update: Update): boolean | void {
    this.valid = update.valid;
    this.value = update.value;

    if (this.valid) {
      const appointmentDate = new Date(this.data.dependence);
      const [hours, minutes] = this.value.split(':') || ['0', '0'];

      if (appointmentDate && this.data.answer.maxTime) {
        this.expirationDate = setHours(appointmentDate, parseInt(hours, 10));
        this.expirationDate = setMinutes(this.expirationDate, parseInt(minutes, 10));

        if (this.expirationDate.getTime() <= appointmentDate.getTime()) {
          this.expirationDate = addDays(this.expirationDate, 1);
        }

        this.valid = !(differenceInHours(this.expirationDate, appointmentDate) > 23);
      }

      this.date = (new Date(this.expirationDate || this.data.dependence)).toLocaleDateString('pt-BR');
    } else {
      this.date = (new Date(this.data.dependence)).toLocaleDateString('pt-BR');
    }

    this.changeDetectorRef.detectChanges();
  }
}

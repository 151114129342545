<p>Selecione o que deseja fazer</p>
<div>
  <button
    mat-button
    class="chat__button closecare-button primary-bg"
    aria-label="Tirar foto"
    (click)="selectPicture()"
  >
    Tirar foto
  </button>
  <button
    mat-button
    class="chat__button closecare-button primary-bg"
    aria-label="Selecionar arquivo"
    (click)="selectFile()"
  >
    Selecionar arquivo
  </button>
</div>
<input
  hidden
  #nativeCamera
  type="file"
  accept="image/*"
  capture="environment"
  id="camera"
  (change)="fileTaken($event.target.files)"
/>
<input
  #filePicker
  type="file"
  accept="image/*, application/pdf"
  hidden
  (change)="fileTaken($event.target.files)"
/>

import {Pipe, PipeTransform} from '@angular/core';

import {Replacers} from './../models/chat.model';

@Pipe({
  name: 'keyReplace'
})

export class KeyReplacePipe implements PipeTransform {
  transform(message: string, replacers: Replacers = {}): string {
    Object.keys(replacers).forEach((key: string) => {
      message = message.split(key).join(replacers[key]);
    });

    return message;
  }
}

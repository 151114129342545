<ng-container *ngIf="currentComponent" [ngSwitch]="currentComponent.type">
  <form class="input-wrapper-container">
    <ng-container *ngSwitchCase="ComponentEnum.COMPONENT_TYPE_TEXT">
      <app-input-text
        label="{{ currentComponent.label }}"
        placeholder="{{ currentComponent.placeholder }}"
        [formControl]="formControl"
      ></app-input-text>
    </ng-container>

    <ng-container *ngSwitchCase="ComponentEnum.COMPONENT_TYPE_DATE">
      <app-input-date
        label="{{ currentComponent.label }}"
        placeholder="{{ currentComponent.placeholder }}"
        [formControl]="formControl"
      ></app-input-date>
    </ng-container>

    <button
      mat-icon-button
      color="primary"
      type="button"
      aria-label="Próximo passo"
      (click)="done()"
      [disabled]="formControl.invalid"
    >
      <mat-icon>send</mat-icon>
    </button>
  </form>
</ng-container>

<div class="dynamic-form-container">
  <div class="dynamic-form">
    <div class="chat-history px-32">
      <app-chat-history
        [chat]="chat"
        [typing]="typing$ | async"
        [step]="step"
        [lastImageIndex]="lastImageIndex"
        (undoEvent)="undo()"
        (openFilePickerEvent)="editFiles()"
      ></app-chat-history>
    </div>

    <div class="component-area mb-32 px-32" *ngIf="response$ | async as response">
      <ng-container *ngIf="(typing$ | async) === false">
        <form *ngIf="currentFormGroup" [formGroup]="currentFormGroup">
          <ng-container
            *ngIf="response.component"
            [ngSwitch]="response.component.type"
          >
            <ng-container *ngSwitchCase="ComponentEnum.COMPONENT_TYPE_TEXT">
              <app-input-wrapper
                [currentComponent]="response.component"
                [formControlName]="response.component.field"
              ></app-input-wrapper>
            </ng-container>

            <ng-container *ngSwitchCase="ComponentEnum.COMPONENT_TYPE_DATE">
              <app-input-wrapper
                [currentComponent]="response.component"
                [formControlName]="response.component.field"
              ></app-input-wrapper>
            </ng-container>

            <ng-container
              *ngSwitchCase="ComponentEnum.COMPONENT_TYPE_TWO_BUTTONS"
            >
              <app-two-buttons
                [currentComponent]="response.component"
                [formControlName]="response.component.field"
              ></app-two-buttons>
            </ng-container>

            <ng-container *ngSwitchCase="ComponentEnum.COMPONENT_TYPE_FILE">
              <button
                class="
                  font-size-16
                  border-radius-24
                  py-16
                  white-font
                  gradient-brand
                  full-width
                "
                (click)="openFilePicker()"
              >
                Continuar
              </button>
            </ng-container>
          </ng-container>
        </form>
      </ng-container>

      <button
        type="button"
        class="
          font-size-16
          border-radius-24
          py-16
          white-font
          gradient-brand
          full-width
        "
        (click)="submit()"
        *ngIf="!response.component && (typing$ | async) === false"
      >
        Enviar
      </button>
    </div>
  </div>
</div>

<app-loading *ngIf="loading"
             title="Enviando"
             body="Normalmente demora alguns segundos">
</app-loading>

import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {SimpleDialogComponent} from '../../components/simple-dialog/simple-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import {Events} from '../../models/analytics';
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltip, MatTooltipDefaultOptions} from '@angular/material/tooltip';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ItemPickerComponent} from '../../components/item-picker/item-picker.component';
import {of} from 'rxjs';
import {LocalStorageService, STORAGE_KEYS} from '../../services/local-storage.service';
import {RecaptchaComponent} from 'ng-recaptcha';
import {AuthService} from '../../services/auth.service';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 8000,
  touchendHideDelay: 8000,
};

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}
  ],
})
export class LoginComponent implements OnInit {
  @ViewChild('reCaptcha', {static: false}) reCaptcha: RecaptchaComponent;
  startDate = new Date('1980-01-01');
  minDate = new Date();
  maxDate = new Date();
  loading;
  form: FormGroup;
  companyId: string;
  env = environment;

  hash: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private logger: NGXLogger,
    private fb: FormBuilder,
    private analytics: AngularFireAnalytics,
    private localStorage: LocalStorageService
  ) {
    this.minDate.setFullYear(this.minDate.getFullYear() - 100);
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 14);
  }

  ngOnInit(): void {
    const cred = this.localStorage.get(STORAGE_KEYS.user_credentials);
    this.companyId = cred?.company_id || '';
    this.form = this.fb.group({
      cpf: [cred?.cpf || '', Validators.required],
      birthday: [cred?.birthday || '', Validators.required],
      save_session: [cred?.save_session || false],
      recaptcha: ['']
    });
  }

  login(): void {
    this.saveCredentials();
    this.reCaptcha.execute();
    this.loading = true;
  }

  resolved(captchaResponse: string): void {
    if (!!captchaResponse) {
      this.loginOrPickCompany(captchaResponse);
    } else {
      this.loading = false;
    }
  }

  private loginOrPickCompany(captchaResponse: string): void {
    const values = this.form.value;

    this.authService.softLoginWithCpf(values.cpf, values.birthday, captchaResponse, this.companyId)
      .pipe(
        mergeMap((response) => {
          // User has more than one company
          if (Array.isArray(response)) {
            this.reCaptcha.reset();

            return ItemPickerComponent.open(this.dialog, {
              header: 'Selecione de qual empresa é o atestado',
              select: 'CONFIRMAR',
              itens: response
            }).afterClosed()
              .pipe(map(companyId => {
                this.companyId = companyId;

                return 'retry'; // try login again
              }));
          }

          this.companyId = response.company_id;

          return of('continue');
        }),
        catchError((error) => {
          this.loading = false;
          this.reCaptcha.reset();
          this.logger.debug(error);
          this.analytics.logEvent(Events.LOGIN_ERROR, {status: error?.status});
          return SimpleDialogComponent.open(
            this.dialog,
            {
              okButton: 'OK',
              title: 'Usuário não encontrado',
              content: 'Tente novamente ou fale com o RH da empresa.'
            }
          ).afterClosed().pipe(map(() => 'stop')); // company not found
        })
      ).subscribe((proceed) => {
        if (proceed === 'continue') {
          const {redirect} = history.state;
          this.router.navigate([redirect || 's/'], {replaceUrl: true})
            .then(() => true)
            .finally(() => this.loading = false);

        } else if (proceed === 'retry') {
          this.login();
        }
      });
  }

  private saveCredentials(): void {
    const values = this.form.value;
    if (values.save_session) {
      const saved = this.localStorage.get(STORAGE_KEYS.user_credentials) || {};
      saved.cpf = values.cpf;
      saved.birthday = values.birthday;
      // saved.company_id = this.companyId,
      saved.save_session = values.save_session;
      this.localStorage.set(STORAGE_KEYS.user_credentials, saved);
    } else {
      this.localStorage.set(STORAGE_KEYS.user_credentials, {});
    }
  }

  handleError(error: any): void {
    this.logger.error('Captcha error', error);
    this.reCaptcha?.reset();
  }

  toggleTooltip(tooltip: MatTooltip, event): void {
    tooltip?.toggle();
    event.stopPropagation();
  }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {CompanyService} from '../services/company.service';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TermsAcceptedGuard implements CanActivate {
  constructor(
    private router: Router,
    private companyService: CompanyService,
    private authService: AuthService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (!this.companyService.rulesAndTermsAccepted()) {
      return this.router.createUrlTree(['/s/termos']);
    }

    return true;
  }
}

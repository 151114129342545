import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoadingComponent} from './loading.component';
import {SpinnerModule} from '../spinner/spinner.module';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
  declarations: [
    LoadingComponent
  ],
  exports: [
    LoadingComponent
  ],
    imports: [
        CommonModule,
        SpinnerModule,
        SharedModule
    ]
})
export class LoadingModule { }

import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Answered, Replacers} from '../../../shared/models/chat.model';

@Component({
  selector: 'app-answer-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss']
})

export class CancelComponent {
  @Input() public label: string;
  @Input() public cancel: Array<string>;
  @Input() public replacers: Replacers;
  @Output() private sendAnswer: EventEmitter<Answered> = new EventEmitter<Answered>();

  public setAnswer(): void {
    this.sendAnswer.emit({
      label: this.label,
      cancel: this.cancel
    });
  }
}

export enum ChatItemEnum {
  ITEM_TYPE_QUESTION_TEXT = 'text',
  ITEM_TYPE_QUESTION_HTML = 'html',
  ITEM_TYPE_QUESTION_IMAGE = 'image',
  ITEM_TYPE_ANSWER_TEXT = 'answer-text',
  ITEM_TYPE_ANSWER_IMAGE = 'answer-image',
}

export enum ComponentEnum {
  COMPONENT_TYPE_TEXT = 'text',
  COMPONENT_TYPE_DATE = 'date',
  COMPONENT_TYPE_TWO_BUTTONS = 'two-buttons',
  COMPONENT_TYPE_FILE = 'file',
}

<mat-toolbar color="primary" class="header">
  <h2 mat-dialog-title>{{data?.answer.title}}</h2>
</mat-toolbar>
<p class="date"><strong>{{date}}</strong></p>
<app-time-picker (updated)="updated($event)"></app-time-picker>
<mat-error class="error" *ngIf="expirationDate && !valid">
  O período não poder maior do que 23 horas
</mat-error>
<mat-dialog-actions align="end">
  <button mat-button color="primary" class="picker__button" (click)="dialogRef.close()"
          aria-label="Cancelar">Cancelar</button>
  <button mat-button color="primary" class="picker__button" [disabled]="!valid" [mat-dialog-close]="value"
          aria-label="Confirmar">Confirmar</button>
</mat-dialog-actions>

import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {EmailComponent} from './email.component';


@NgModule({
  imports: [
    ReactiveFormsModule,
    MatInputModule
  ],
  declarations: [
    EmailComponent
  ],
  entryComponents: [
    EmailComponent
  ],
  exports: [
    EmailComponent
  ]
})

export class EmailModule {}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {IMaskModule} from 'angular-imask';
import {SharedModule} from '../../../../../../shared/shared.module';
import {NumberComponent} from './number.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    IMaskModule,
    SharedModule
  ],
  declarations: [
    NumberComponent
  ],
  entryComponents: [
    NumberComponent
  ],
  exports: [
    NumberComponent
  ]
})

export class NumberModule {}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ItemPickerComponent} from './item-picker.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {MatRadioModule} from '@angular/material/radio';



@NgModule({
  declarations: [ItemPickerComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatListModule,
    MatRadioModule
  ],
  exports: [ItemPickerComponent]
})
export class ItemPickerModule {}

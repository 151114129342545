import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizedGuard } from 'src/app/guards/authorized.guard';
import { CodeGuard } from 'src/app/guards/code.guard';
import { TermsAcceptedGuard } from 'src/app/guards/terms_accepted.guard';
import { BaseComponent } from '../base/base.component';
import { FilePreviewDialogComponent } from './../file-preview/file-preview-dialog.component';
import { FileSelectedDialogComponent } from './../file-selected/file-selected-dialog.component';
import { DynamicFormComponent } from './dynamic-form.component';
import { DynamicFormsResolver } from './dynamic-forms.resolver,';

const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthorizedGuard, CodeGuard, TermsAcceptedGuard],
    children: [{
      path: '',
      component: DynamicFormComponent,
      resolve: { data: DynamicFormsResolver },
    }]
  },
  {
    path: 'arquivos',
    component: FileSelectedDialogComponent,
    outlet: 'modal',
    canActivate: [AuthorizedGuard, CodeGuard, TermsAcceptedGuard]
  },
  {
    path: 'visualizacao',
    component: FilePreviewDialogComponent,
    outlet: 'modal',
    canActivate: [AuthorizedGuard, CodeGuard, TermsAcceptedGuard]
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DynamincFormRoutingModule {}

import {Injectable} from '@angular/core';

import {ItemPicker} from '../../../components/item-picker/item-picker.model';

@Injectable()
export class PickersBuilder {
  private pickers: Array<ItemPicker>;

  public build($enum): Array<ItemPicker> {
    this.pickers = [];

    this.buildPickers($enum);

    return this.pickers;
  }

  private buildPickers($enum: {[key: string]: string}): void {
    Object.keys($enum).forEach((key: string) => {
      this.addPicker(key, $enum[key]);
    });
  }

  private addPicker(id: string | number, name: string): void {
    this.pickers.push({id, name});
  }
}

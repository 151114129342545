<div class="container">
  <form [formGroup]="form">
    <div>
      <mat-form-field appearance="standard">
        <mat-label>CPF</mat-label>
        <input matInput data-test="input-cpf" formControlName="cpf" appCpfValidator name="cpf" inputmode="numeric" [imask]="{mask: '000.000.000-00'}">
        <button mat-button mat-icon-button matSuffix
                (click)="toggleTooltip(tooltipCpf, $event)">
          <mat-icon color="accent" #tooltipCpf="matTooltip"
                    matTooltip="Seu CPF é necessário para identificação do seu cadastro no contexto de gestão de atestados e saúde corporativa.">
            info
          </mat-icon>
        </button>
        <mat-error *ngIf="!form.controls.cpf.valid && !form.controls.cpf.pristine">CPF inválido</mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>Data de nascimento</mat-label>
        <input matInput data-test="input-birthdate" [matDatepicker]="picker" formControlName="birthday" required
               [min]="minDate" [max]="maxDate" appMaskDate name="birthday" inputmode="numeric">
        <button mat-icon-button matSuffix (click)="toggleTooltip(tooltipBirthday, $event)">
          <mat-icon color="accent" #tooltipBirthday="matTooltip"
                    matTooltip="Sua data de nascimento é importante para confirmação de identidade e validação dos registros no contexto de gestão de atestados e saúde corporativa.">
            info
          </mat-icon>
        </button>
        <mat-datepicker touchUi #picker startView="multi-year" [startAt]="startDate"></mat-datepicker>
        <mat-error *ngIf="!form.controls.birthday.valid && !form.controls.birthday.pristine">Data inválida</mat-error>
      </mat-form-field>
      <re-captcha #reCaptcha
      [siteKey]="env.recaptcha_key"
      (resolved)="resolved($event)"
                  errorMode="handled"
                  (error)="handleError($event)"
                  formControlName="recaptcha"
                  size="invisible">
      </re-captcha>
    </div>

    <div class="checkbox-container" fxLayout="column" fxLayoutAlign="space-around start" fxLayoutGap="16px">
      <mat-checkbox formControlName="save_session" color="primary">
        Lembrar meus dados aqui
      </mat-checkbox>
    </div>
  </form>

  <div>
    <button mat-raised-button data-test="submit" class="closecare-button primary-bg" (click)="login()"
      [disabled]="!form.valid" aria-label="Próximo">
      Próximo
    </button>

    <div class="recaptcha-container">
      Este site é protegido por reCAPTCHA.
      <a href="https://policies.google.com/privacy">Política de Privacidade</a> e
      <a href="https://policies.google.com/terms">Termos de Uso</a> do Google se aplicam.
    </div>
  </div>
</div>

<app-loading *ngIf="loading"></app-loading>

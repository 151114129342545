import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {EMPTY, from, Observable, of} from 'rxjs';
import {catchError, map, mergeMap, share, shareReplay} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {API_SETTINGS, ApiService} from './api.service';
import {CompanySettings} from '../models/company';
import {AuthService} from './auth.service';
import {Term} from '../models/term';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import {Messages} from '../models/message';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private settings$;
  public companyId$: Observable<string>;
  private rulesAccepted = false;
  public messages$: Observable<Messages>;

  constructor(
    private db: AngularFirestore,
    private logger: NGXLogger,
    private api: ApiService,
    private authService: AuthService
  ) {
    this.companyId$ = authService.user$.pipe(
      mergeMap((user) => from(user.getIdTokenResult())),
      map((token) => token.claims?.company_id)
    );

    this.messages$ = this.getMessages();
  }

  private getMessages(): Observable<Messages> {
    return this.companyId$.pipe(
      mergeMap((companyId) => {
        return this.db.collection('messages').doc<Messages>(companyId).valueChanges();
      }),
      catchError(() => of(null)),
      map((m) => new Messages(m)),
      share()
    );
  }

  public getTerms(): Observable<Term> {
    return this.companyId$.pipe(
      mergeMap((companyId) => {
        return this.db.collection<Term>('terms', ref => {
          return ref
            .where('company_id', '==', companyId)
            .orderBy('version', 'desc')
            .limit(1);
        }).get().pipe(
          mergeMap((snapshot) => {
            if (!snapshot || snapshot.empty) {
              return this.db.collection<Term>('terms', ref => {
                return ref
                  .where('company_id', '==', '')
                  .orderBy('version', 'desc')
                  .limit(1);
              }).get();
            }
            return of(snapshot);
          }),
          map((snapshot) => {
            if (!!snapshot && snapshot.empty === false) {
              const t = snapshot?.docs[0]?.data() as Term;
              t.id = snapshot?.docs[0]?.id;
              return t;
            } else {
              return null;
            }
          }),
          catchError(err => {
            this.logger.error(err);
            return EMPTY;
          }),
        );
      }));
  }

  public acceptTerms(term: Term): void {
    this.rulesAccepted = true;
    this.authService.user$.pipe(
      mergeMap((user) => {
        const doc = {
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          version: term.version,
          user_id: user?.uid
        };
        return of(this.db.collection('terms').doc(term.id).collection('accepted')
          .add(doc))
          .pipe(
            catchError(err => {
              this.logger.error(err);
              return of(null);
            })
          );
      })).subscribe();
  }

  public rulesAndTermsAccepted(): boolean {
    return this.rulesAccepted;
  }

  getSettings(): Observable<CompanySettings> {
    this.settings$ = this.settings$ || this.api.http.get<CompanySettings>(API_SETTINGS)
      .pipe(shareReplay(1));
    return this.settings$;
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {SharedModule} from '../../../../../shared/shared.module';
import {ChatPipesModule} from '../../../shared/pipes/pipes.module';
import {CancelComponent} from './cancel.component';


@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        SharedModule,
        ChatPipesModule
    ],
    declarations: [
        CancelComponent
    ],
    exports: [
        CancelComponent
    ],
    entryComponents: [
        CancelComponent
    ]
})

export class CancelModule {}

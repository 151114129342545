import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SickNoteTypesResponse, SickNoteType} from '../models/sick-note';
import {ApiService, API_DOC_TYPES} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class DocTypeService {
  public selected: SickNoteType;

  constructor(private apiService: ApiService) {}

  public getDocTypes(): Observable<SickNoteType[]> {
    return this.apiService.http.get<SickNoteTypesResponse>(API_DOC_TYPES)
      .pipe(
        map((listSickNote: SickNoteTypesResponse) => listSickNote.sick_notes_types),
        map((types) => {
          return types.sort((a, b) => a?.name?.localeCompare(b?.name, undefined, {numeric: true}));
        })
      );
  }
}

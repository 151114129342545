import {NgModule} from '@angular/core';
import {SharedModule} from '../../../../../shared/shared.module';
import {DateModule} from './date/date.module';
import {TimeModule} from './time/time.module';
import {PickerModule} from './picker/picker.module';
import {ButtonModule} from './button/button.module';
import {ButtonsComponent} from './buttons.component';


@NgModule({
  imports: [
    SharedModule,
    DateModule,
    TimeModule,
    PickerModule,
    ButtonModule
  ],
  declarations: [
    ButtonsComponent
  ],
  entryComponents: [
    ButtonsComponent
  ],
  exports: [
    ButtonsComponent
  ]
})

export class ButtonsModule {}

import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ItemPicker} from '../../../../../../components/item-picker/item-picker.model';
import {ItemPickerComponent} from '../../../../../../components/item-picker/item-picker.component';
import {Answer, Answered, AnswerPickers, Send} from '../../../../shared/models/chat.model';

@Component({
  selector: 'app-button-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.scss']
})

export class PickerComponent implements OnInit {
  private picker: FormControl = new FormControl('', Validators.required);

  @Input() public answer: Answer;
  @Input() public pickers: Array<ItemPicker>;
  @Output() private answered: EventEmitter<Answered> = new EventEmitter<Answered>();

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.picker.valueChanges
      .subscribe((value: string) => setTimeout(() => this.setAnswer(value)));
  }

  public openDialogPicker(): void {
    ItemPickerComponent.open(this.dialog, {
      header: this.answer.button.label,
      select: 'CONFIRMAR',
      itens: this.pickers,
      releaseClose: true
    })
      .afterClosed()
      .subscribe((value: string) => this.pickerSelected(value));
  }

  private pickerSelected(value: string): void {
    if (this.answer.picker === AnswerPickers.docTypes) {
      return this.picker.setValue(value || '');
    }

    this.picker.setValue(value);
  }

  private setAnswer(value: string): void {
    if (value) {
      this.answered.emit(this.buildAnswer(value));
    }
  }

  private buildAnswer(value: string): Answered {
    const answered: Answered = {
      label: (this.pickers.find((picker: ItemPicker) => picker.id === value)).name,
      send: { value }
    };

    if (this.answer.reference) {
      (answered.send as Send).reference = this.answer.reference.key;
    }

    return answered;
  }
}

<h2 mat-dialog-title>{{data?.header}}</h2>
<mat-dialog-content>
  <mat-list>
    <mat-list-item *ngFor="let item of data?.itens">
      <mat-radio-button data-test="picker-item" [checked]="false" [value]="item.id" (change)="selection = $event.value">{{item.name}}</mat-radio-button>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button data-test="picker-submit" color="primary" [disabled]="!selection" [mat-dialog-close]="selection"
          [attr.aria-label]="data?.select">{{data?.select}}</button>
</mat-dialog-actions>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentTypeList } from '../pages/document-categories/entities/document-types.entity';
import {
    ApiService, API_DOCUMENT_TYPES_LIST
} from './api.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentTypesService {
  constructor(private api: ApiService) {}

  getDocumentsTypes(): Observable<DocumentTypeList> {
    return this.api.http.get<DocumentTypeList>(API_DOCUMENT_TYPES_LIST);
  }
}

import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';
import { parse, format, isValid } from 'date-fns';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  useUtcForDisplay = true;

  format(date: Date): string {
    return format(date, 'dd/MM/yyyy');
  }

  parse(value: any): Date | null {
    if (value && typeof value === 'string') {
      value = value.replace('_', '');
    }
    const date = parse(value, 'dd/MM/yyyy', new Date());
    if (!isValid(date)) {
      return this.invalid();
    }
    return date;
  }
}

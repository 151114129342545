import {NgModule} from '@angular/core';
import {InputsModule} from './inputs/input.module';
import {ButtonsModule} from './buttons/buttons.module';
import {UserDataModule} from './corrections/user-data/user-data.module';
import {DoctorDataModule} from './corrections/doctor-data/doctor-data.module';
import {CancelModule} from './cancel/cancel.module';


@NgModule({
  imports: [
    InputsModule,
    ButtonsModule,
    UserDataModule,
    DoctorDataModule,
    CancelModule
  ],
  exports: [
    InputsModule,
    ButtonsModule,
    UserDataModule,
    DoctorDataModule,
    CancelModule
  ]
})

export class AnswersModule {}

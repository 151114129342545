export interface Professional {
  id: number;
  state: string;
  name: string;
  type: string;
  status: string;
  specialty: string[];
  created_at: string;
}

export interface ProfessionalType {
  id: string;
  code: string;
  label: string;
}

export interface ListResponse<T> {
  items: T[];
}

export const ProfessionalCRM = 'CRM';

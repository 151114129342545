import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DocumentTypesService } from 'src/app/services/document-types.service';
import { DocumentCategories, DocumentCategory, DocumentTypeList } from './entities/document-types.entity';

@UntilDestroy()
@Component({
  selector: 'app-document-categories',
  templateUrl: './document-categories.component.html',
  styleUrls: ['./document-categories.component.scss']
})
export class DocumentCategoriesComponent {
  documentTypes: DocumentTypeList;

  constructor(
    private documentTypesService: DocumentTypesService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.data
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.documentTypes = data.documentTypes;
      });
  }

  selectCategory(category: DocumentCategory) {
    if (category.label === DocumentCategories.SICK_NOTE) {
      this.router.navigate(['/s/documento']);
    } else {
      this.router.navigate(['/t'], { state: { category }});
    }
  }
}

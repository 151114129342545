import {Component, Input, Output, EventEmitter} from '@angular/core';

import {AnswerType, Answer, Replacers, Answered, Update} from '../../../shared/models/chat.model';

@Component({
  selector: 'app-answer-inputs',
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.scss']
})

export class InputsComponent {
  public valid: boolean;
  public answered: Answered;
  public answerType: {[key: string]: string} = AnswerType;

  @Input() public answer: Answer;
  @Input() public max: boolean;
  @Input() public dependence: string;
  @Input() public replacers: Replacers;
  @Input() public prefix: any;
  @Output() public sendAnswer: EventEmitter<Answered> = new EventEmitter<Answered>();
  @Output() private layoutUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  public updated(update: Update): void {
    this.valid = update.valid;
    this.answered = update.answered;

    if (update.layout) {
      setTimeout(() => this.layoutUpdated.emit());
    }
  }
}

import {NgModule} from '@angular/core';
import {MaskDateDirective} from './mask-date.directive';


@NgModule({
  declarations: [
    MaskDateDirective
  ],
  imports: [],
  exports: [MaskDateDirective]
})
export class MaskDateModule { }

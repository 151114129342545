import {ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {CompanyService} from '../services/company.service';
import {CompanySettings} from '../models/company';

@Injectable({
  providedIn: 'root'
})
export class EntryModeResolverService {
  constructor(
    public router: Router,
    private companyService: CompanyService
  ) {}

  resolve(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot, route: ActivatedRoute):
    Observable<CompanySettings> {

    return this.companyService.getSettings().pipe(
      take(1)
    );
  }
}

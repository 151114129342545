import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {LoginComponent} from './login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {IMaskModule} from 'angular-imask';
import {CpfValidatorDirectiveDirective} from '../../shared/cpf-validator-directive.directive';
import {MatButtonModule} from '@angular/material/button';
import {LoadingModule} from '../../components/loading/loading.module';
import {MaskDateModule} from '../../directives/mask-date/mask-date.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RecaptchaFormsModule, RecaptchaModule} from 'ng-recaptcha';


@NgModule({
  declarations: [
    LoginComponent,
    CpfValidatorDirectiveDirective
  ],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    IMaskModule,
    MatButtonModule,
    LoadingModule,
    MaskDateModule,
    MatCheckboxModule,
    MatIconModule,
    MatTooltipModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ]
})
export class LoginModule { }

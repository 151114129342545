import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentTypesService } from '../../services/document-types.service';
import { DocumentCategories, DocumentCategory, DocumentTypeList } from './entities/document-types.entity';

@Injectable({ providedIn: 'root' })
export class DocumentTypesResolver implements Resolve<DocumentTypeList> {
  constructor(
    public router: Router,
    private documentTypesService: DocumentTypesService
  ) {}

  resolve(): Observable<DocumentTypeList> {
    const sickNoteTypes$ = of({
        categories: [
        {
            id: -1,
            label: DocumentCategories.SICK_NOTE,
            types: [
            {
                label: 'Atestado'
            },
            {
                label: 'Declarações'
            },
            {
                label: 'Acompanhamento'
            }
            ]
        } as DocumentCategory
        ]
    });

    const occupationalTypes$ = this.documentTypesService.getDocumentsTypes();

    return forkJoin([sickNoteTypes$, occupationalTypes$]).pipe(map(([sickNoteTypes, occupationalTypes]) => ({
        categories: sickNoteTypes.categories.concat(occupationalTypes.categories)
    })));
  }
}

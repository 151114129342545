<div fxLayout="row" fxLayoutAlign="center center" class="parent">
  <div fxLayout="column" fxLayoutAlign="space-between center" class="btns btns--left">
    <mat-icon (click)="operate('h', '+')">keyboard_arrow_up</mat-icon>
    <mat-icon (click)="operate('h', '-')">keyboard_arrow_down</mat-icon>
  </div>
  <mat-form-field class="hours" appearance="outline" fxFlex="30">
    <mat-label>Horas</mat-label>
    <input matInput type="number" [min]="minHours" [max]="maxHours" [formControl]="hourCtrl" [imask]="{mask: '00'}">
  </mat-form-field>
  <span class="dots">:</span>
  <mat-form-field class="minutes" appearance="outline" fxFlex="30">
    <mat-label>Minutos</mat-label>
    <input matInput type="number" [min]="minMinutes" [max]="maxMinutes" [formControl]="minutesCtrl" [imask]="{mask: '00'}">
  </mat-form-field>
  <div fxLayout="column" fxLayoutAlign="space-between center" class="btns btns--right">
    <mat-icon (click)="operate('m', '+')">keyboard_arrow_up</mat-icon>
    <mat-icon (click)="operate('m', '-')">keyboard_arrow_down</mat-icon>
  </div>
</div>


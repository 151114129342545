<p>{{data?.bottomSheet?.description}}</p>
<div *ngFor="let field of data?.bottomSheet?.fields">
    <app-input-number
        class="border-color-primary answer__field input__wrapper"
        fxLayoutAlign="stretch"
        *ngIf="field.type === answerType?.number"
        [placeholder]="field.placeholder | keyReplace: data.replacers"
        (updated)="updated(answerType.number, $event)"
    >
    </app-input-number>
    <app-input-text
        class="border-color-primary answer__field input__wrapper input__wrapper--picker"
        fxLayoutAlign="stretch"
        *ngIf="field.type === answerType?.picker"
        [placeholder]="field.placeholder"
        [value]="state?.label"
        (updated)="updated(answerType.picker, $event)"
        (click)="openItemPicker()"
    >
    </app-input-text>
</div>
<app-button
    [button]="data?.bottomSheet?.button"
    [disabled]="fieldsInvalid"
    (click)="sendAnswer()"
>
</app-button>
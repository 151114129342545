import { Pipe, PipeTransform } from '@angular/core';
import { DocumentType } from 'src/app/pages/document-categories/entities/document-types.entity';

@Pipe({
    name: 'typeListToString'
})
export class TypeListToStringPipe implements PipeTransform {
    transform(typeList: DocumentType[]): string {
        return typeList.map(type => type.label).join(', ');
    }
}

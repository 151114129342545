import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SafeHtmlPipe} from './safe-html.pipe';
import {KeyReplacePipe} from './key-replace.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SafeHtmlPipe,
    KeyReplacePipe
  ],
  exports: [
    SafeHtmlPipe,
    KeyReplacePipe
  ]
})
export class ChatPipesModule {}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { AUTHENTICATED_DOMAINS, environment } from '../../environments/environment';
import { AuthService } from './auth.service';

// case insensitive check against config and value
const startsWithAny = (arr: string[] = []) => (value = '') => arr.some(test => value.toLowerCase().startsWith(test.toLowerCase()));
const isAbsoluteURL = startsWithAny(['http', '//']);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService,
              private logger: NGXLogger) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authenticated: boolean;
    if (isAbsoluteURL(request.url)) {
      const hostname = new URL(request.url).hostname;
      authenticated = AUTHENTICATED_DOMAINS.some(domain => hostname.includes(domain));
    } else {
      authenticated = true;
    }

    if (!authenticated || request.headers.has('Authorization')) {
      return next.handle(request);
    }

    request = request.clone({
      setParams: {
        key: environment.closecareApiKey
      }
    });

    return this.auth.token$.pipe(
      take(1),
      catchError((error) => {
        this.logger.error(error);
        return '';
      }),
      map((token) => {
        if (token) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            },
          });
        }
        return request;
      }),
      mergeMap((r) => next.handle(r))
    );
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {EditComponent} from './edit.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule
  ],
  declarations: [
    EditComponent
  ],
  exports: [
    EditComponent
  ]
})
export class EditModule {}

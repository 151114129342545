import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DocumentCategory } from 'src/app/pages/document-categories/entities/document-types.entity';
import { AuthService } from 'src/app/services/auth.service';
import { DocTypeService } from '../../services/doc-type.service';

@UntilDestroy()
@Component({
  selector: 'app-document-types',
  templateUrl: './document-types.component.html',
  styleUrls: ['./document-types.component.scss']
})
export class DocumentTypesComponent {
  @Input()
  selectedCategory: DocumentCategory;
  dynamicFormsEnabled: boolean;
  formControl: FormControl;

  constructor(
    private router: Router,
    private docTypeService: DocTypeService,
    private authService: AuthService,
  ) {
    const state = this.router.getCurrentNavigation().extras.state;
    this.selectedCategory = state.category;
    this.formControl = new FormControl();
  }

  get disabled() {
    return this.formControl.pristine || (this.formControl.dirty && this.formControl.invalid);
  }

  next(): void {
    this.authService.userClaims
      .pipe(untilDestroyed(this))
      .subscribe(([user, claims]) => {
        const selectedType = this.formControl.value;
        const state = {
          categoryId: this.selectedCategory.id,
          typeId: selectedType.id,
          organizationTypeId: selectedType.id,
          personId: claims?.person_id,
        };
        this.router.navigate(['/f'], { state });
      });
  }
}

import {Component, EventEmitter, Input, OnInit, Inject, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {Update} from '../../../../../../shared/models/chat.model';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.css']
})
export class TimePickerComponent implements OnInit {
  public hourCtrl: FormControl;
  public minutesCtrl: FormControl;

  public minHours = 0;
  public minMinutes = 0;
  public maxHours = 23;
  public maxMinutes = 59;

  @Input() public maxHoursHint;
  @Output() updated: EventEmitter<Update> = new EventEmitter<Update>();

  constructor(@Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit(): void {
    this.hourCtrl = new FormControl('', [Validators.required, Validators.max(this.maxHours)]);
    this.minutesCtrl = new FormControl('', [Validators.required, Validators.max(this.maxMinutes)]);

    combineLatest(this.hourCtrl.valueChanges, this.minutesCtrl.valueChanges)
      .pipe(
        map(([hours, minutes]) => {
          const h = parseInt(hours, 10).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
          const m = parseInt(minutes, 10).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
          return `${h}:${m}`;
        }),
        distinctUntilChanged()
      )
      .subscribe((value: string) => this.sendUpdate(value));
  }

  public operate(unit: 'h' | 'm', operation: '+' | '-'): void {
    const ctrl: FormControl = unit === 'h' ? this.hourCtrl : this.minutesCtrl;
    const current = parseInt(ctrl.value || '0', 10);
    const max = unit === 'h' ? this.maxHours : this.maxMinutes;
    const newValue = operation === '+' ? Math.min(current + 1, max) : Math.max(current - 1, 0);
    ctrl.setValue(newValue.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}));
  }

  private sendUpdate(value): void {
    this.updated.emit({
      valid: this.hourCtrl.valid && this.minutesCtrl.valid,
      value
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { formatISO } from 'date-fns';
import { SimpleDialogComponent } from '../../components/simple-dialog/simple-dialog.component';
import { SickNoteService } from '../../services/sick-note.service';

@Component({
    selector: 'app-sick-note',
    templateUrl: './sick-note.component.html',
    styleUrls: ['./sick-note.component.css']
})
export class SickNoteComponent implements OnInit {
    minDate = new Date(Date.now() - (180 * 24 * 60 * 60 * 1000));
    maxDate = new Date(Date.now() + (7 * 24 * 60 * 60 * 1000));
    today = new Date();
    loading = false;
    appointmentDate = new FormControl('', [Validators.required]);

    constructor(private dialog: MatDialog,
                private sickNoteService: SickNoteService,
                private router: Router) { }

    ngOnInit(): void {
        this.appointmentDate.valueChanges.subscribe((value) => {
            if (this.appointmentDate.valid) {
                const utc = formatISO(value);
                this.sickNoteService.setData({ appointment_date: utc });
            }
        });
    }

    next(): void {
        this.router.navigate(['s/acompanhar'], { replaceUrl: true });
    }
}

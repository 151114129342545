import {NgModule} from '@angular/core';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';

import {SharedModule} from '../../shared/shared.module';
import {LoadingModule} from '../../components/loading/loading.module';
import {FilePreviewDialogModule} from '../file-preview/file-preview-dialog.module';
import {FileSelectedDialogModule} from '../file-selected/file-selected-dialog.module';
import {ChatPipesModule} from './shared/pipes/pipes.module';
import {PickersBuilder} from './shared/pickers-builder';
import {KeysReplacer} from './shared/keys-replacer';
import {MessageModule} from './components/message/message.module';
import {AnswersModule} from './components/answers/answers.module';
import {ChatRoutingModule} from './chat-routing.module';
import {ChatComponent} from './chat.component';

@NgModule({
  imports: [
    MatBottomSheetModule,
    MatButtonModule,
    SharedModule,
    LoadingModule,
    FilePreviewDialogModule,
    FileSelectedDialogModule,
    ChatPipesModule,
    MessageModule,
    AnswersModule,
    ChatRoutingModule
  ],
  providers: [
    PickersBuilder,
    KeysReplacer
  ],
  declarations: [
    ChatComponent
  ]
})

export class ChatModule {}

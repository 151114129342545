import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ChatItemEnum } from '../../dynamic-form.enums';
import { ChatItemType } from '../../entities/dynamic-form.entity';

@Component({
  selector: 'app-chat-history',
  templateUrl: './chat-history.component.html',
  styleUrls: ['./chat-history.component.scss'],
})
export class ChatHistoryComponent implements OnChanges {
  @Input()
  chat: ChatItemType[];

  @Input()
  typing: boolean;

  @Input()
  step: number;

  @Input()
  lastImageIndex: number;

  @Output()
  undoEvent: EventEmitter<boolean>;

  @Output()
  openFilePickerEvent: EventEmitter<boolean>;

  constructor() {
    this.undoEvent = new EventEmitter<boolean>();
    this.openFilePickerEvent = new EventEmitter<boolean>();
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.autoScroll();
    }, 100);
  }

  autoScroll(): void {
    const chatHistories = document.getElementsByClassName('chat-history');
    if (chatHistories.length > 0) {
      chatHistories[0].scrollTop = chatHistories[0].scrollHeight;
    }
  }

  undo() {
    this.undoEvent.emit(true);
  }

  openFilePicker() {
    this.openFilePickerEvent.emit(true);
  }

  lastFile(index: number) {
    return this.lastImageIndex === index;
  }

  get ChatItemEnum() {
    return ChatItemEnum;
  }
}

import {SickNoteStatus} from './sick-note';

export interface StatusMessage {
  title: string;
  body: string;
}

export class Messages {
  company_id: string;
  status: {
    triage: StatusMessage;
    pending: StatusMessage;
    invalid: StatusMessage;
    valid: StatusMessage
  };
  after_send_notice: string;

  constructor(m: Messages) {
    this.company_id = m?.company_id;
    this.status = {
      triage: {
        title: m?.status?.triage?.title || 'Comparecer ao ambulatório',
        body: m?.status?.triage?.body || 'Você precisa comparecer ao ambulatório da empresa para validar seu atestado. Procure o RH se tiver alguma dúvida.'
      },
      pending: {
        title: m?.status?.pending?.title || 'Em análise',
        body: m?.status?.pending?.body || 'Seu atestado foi recebido e será analisado pela empresa. Atente-se às mensagens de email/SMS sobre o andamento.'
      },
      invalid: {
        title: m?.status?.invalid?.title || 'Recusado',
        body: m?.status?.invalid?.body || 'Seu atestado foi recusado pelos motivos abaixo:'
      },
      valid: {
        title: m?.status?.valid?.title || 'Aprovado',
        body: m?.status?.valid?.body || 'Seu atestado foi aprovado!'
      },
    };
    this.after_send_notice = m?.after_send_notice || 'Guarde o atestado original, ele pode ser necessário';
  }

  getMessageByStatus(status: SickNoteStatus): StatusMessage {
    switch (status) {
      case SickNoteStatus.TRIAGE:
        return this.status.triage;
      case SickNoteStatus.VALID:
        return this.status.valid;
      case SickNoteStatus.INVALID:
        return this.status.invalid;
      default:
        return this.status.pending;
    }
  }
}

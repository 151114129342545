import { DocumentStatus } from './documents';

export class Message {
    id: string;
    title: string;
    body: string;
    notice: string;
    type: string;
    document_type_id: string;
    status: string[];
}

export class MessagesData {
    count: number;
    messages: Message[];
}

export const getDefaultMessages = (status: string): { title: string; body: string; notice: string } => {
    switch (status) {
        case DocumentStatus.VALID: return {
            title: 'Aprovado',
            body: 'Seu documento foi aprovado!',
            notice: 'Guarde o documento original, ele pode ser necessário.',
        };
        case DocumentStatus.INVALID: return {
            title: 'Recusado',
            body: 'Seu documento foi recusado pelos motivos abaixo:',
            notice: 'Guarde o documento original, ele pode ser necessário.',
        };
        case DocumentStatus.TRIAGE: return {
            title: 'Comparecer ao ambulatório',
            body: 'Você precisa comparecer ao ambulatório da empresa para validar seu documento. Procure o RH se tiver alguma dúvida.',
            notice: 'Guarde o documento original, ele pode ser necessário.',
        };
        default: return {
            title: 'Em análise',
            body: 'Seu documento foi recebido e será analisado pela empresa. Atente-se às mensagens de email/SMS sobre o andamento.',
            notice: 'Guarde o documento original, ele pode ser necessário.',
        };
    }
};

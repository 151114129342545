import {Injectable} from '@angular/core';
import {combineLatest, EMPTY, from, Observable, of, throwError} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {NGXLogger} from 'ngx-logger';
import 'firebase/compat/auth';
import {API_PERSON_TOKEN, API_USER_HMAC, ApiService} from './api.service';
import {formatDate} from '@angular/common';
import firebase from 'firebase/compat/app';
import {AuthResponse, Item} from '../models/auth-response';
import {COMPANY_TYPE} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private api: ApiService,
    private angularAuth: AngularFireAuth,
    private logger: NGXLogger
  ) {}

  public get token$(): Observable<string | null> {
    return this.angularAuth.idToken;
  }

  public get user$(): Observable<firebase.User | null> {
    return this.angularAuth.user;
  }

  public get userClaims(): Observable<[firebase.User, any]> {
    return this.user$.pipe(
      mergeMap((user) => {
        if (!user) {
          return EMPTY;
        }
        const claims = user?.getIdTokenResult()?.then((tr) => tr?.claims as any);
        return combineLatest([of(user), from(claims)]);
      })
    );
  }

  /**
   * return encoded string to identify user
   */
  softLoginWithCpf(cpf: string, birthday: Date, captchaResponse: string, companyId?: string)
    : Observable<AuthResponse | Item[]> {
    const payload = {
      cpf,
      birthday: formatDate(birthday, 'yyyy-MM-dd', 'pt-BR'),
      company_id: companyId,
      captcha_response: captchaResponse,
      company_type: COMPANY_TYPE
    };

    return this.api.http.post<AuthResponse>(API_PERSON_TOKEN, payload).pipe(
      mergeMap(res => {
        return from(
          this.angularAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(() => this.angularAuth.signInWithCustomToken(res.jwt))
            .then(() => res)
            .catch(error => {
              this.logger.error(error);
            })
        );
      }),
      catchError(err => {
        if (err?.status === 409) {
          return of(err.error?.companies);
        }
        return throwError(err);
      })
    );
  }

  getUserHmacHash(app: string): Observable<string> {
    return this.api.http.get<{hash: string}>(API_USER_HMAC(app)).pipe(
      map(result => result?.hash)
    );
  }
}

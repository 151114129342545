<input
    required
    matInput
    data-test="input-phone"
    class="input__field"
    autocomplete="off"
    inputmode="tel"
    type="tel"
    name="phone"
    [placeholder]="placeholder"
    [imask]="phoneMask"
    [formControl]="phone"
>
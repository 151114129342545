import { Component, Input, OnDestroy, Optional, Self } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NgControl
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { TwoButtonsAnswer } from '../../dynamic-form.classes';
import { ComponentType, OptionType } from '../../entities/dynamic-form.entity';

@Component({
  selector: 'app-two-buttons',
  templateUrl: './two-buttons.component.html',
  styleUrls: ['./two-buttons.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: TwoButtonsComponent,
    },
  ],
})
export class TwoButtonsComponent
  implements OnDestroy, ControlValueAccessor, MatFormFieldControl<OptionType> {
  @Input()
  currentComponent: ComponentType;

  @Input()
  set value(value: OptionType) {
    this.formControl.setValue(value);
    this.stateChanges.next();
  }
  get value() {
    return this.formControl.value;
  }

  stateChanges = new Subject<void>();

  formControl: FormControl;

  id: string;
  focused: boolean;
  empty: boolean;
  shouldLabelFloat: boolean;
  required: boolean;
  disabled: boolean;
  placeholder: string;
  errorState: boolean;
  controlType?: string;
  autofilled?: boolean;
  userAriaDescribedBy?: string;

  onChange = (value: any) => {};
  onTouched = () => {};

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private fb: FormBuilder
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.formControl = new FormControl('');
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  writeValue(value: OptionType): void {
    this.value = value;
  }
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  setDisabledState?(isDisabled: boolean): void {}
  setDescribedByIds(ids: string[]): void {}
  onContainerClick(event: MouseEvent): void {}

  done(value: OptionType) {
    this.onChange(new TwoButtonsAnswer(value));
  }
}

import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {isValid} from '@fnando/cpf';

@Directive({
  selector: '[appCpfValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: CpfValidatorDirectiveDirective, multi: true}]
})
export class CpfValidatorDirectiveDirective implements Validator {
  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    return isValid(control.value) ? null : { invalidCpf: {value: control.value }};
  }

}

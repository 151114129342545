import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DocumentTypesComponent } from './document-types.component';



@NgModule({
  declarations: [
    DocumentTypesComponent
  ],
  exports: [
    DocumentTypesComponent
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    ReactiveFormsModule
  ]
})
export class DocumentTypesModule { }

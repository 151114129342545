import {ICD, Metadata, Person, Professional} from './core-ts';
import {REASONS} from '../pages/status/status.settings';

export enum SickNoteStatus {
  RAW = 'raw',
  PENDING = 'pending',
  FILLED = 'filled',
  VERIFIED = 'verified',
  WARNING = 'warning',
  TRIAGE = 'triage',
  VALID = 'valid',
  INVALID = 'invalid',
}

export interface FileData {
  id: string;
  metadata: {
    content_type: string;
    updated_at: string;
  };
  name: string;
  path: string;
  signed_url: string;
}

export interface ISickNoteFields {
  appointment_date?: string;
  expiration_date?: string;
  icds: ICD[];
  files: FileData[];
  image_path?: string;
  link?: string;
  public_notes?: {
    message?: string;
    reasons?: string[];
  };
  person_id: string;
  premises?: string;
  secret?: string;
  professional?: Professional;
  type_id: string;
  vaccine?: {
    name: string;
    dose: number;
  };
}

export interface IExtendedSickNote {
  id: string;
  company_id: string;
  status: SickNoteStatus;
  short_id: string;
  details: {
    alerts: {
      [key: string]: boolean
    },
    fields_status: {
      [key: string]: 'verified' | 'warning'
    },
  };
  fields: ISickNoteFields;
  extras: {
    location: Location;
    person: Person;
    image_url: string;
    type: SickNoteType;
    metadata: Metadata;
    status_reasons: StatusReason[];
  };
}

export interface SickNoteBody {
  file?: File;
  appointment_date?: string;
  expiration_date?: string;
  icds?: string;
  premises?: string;
  professional_type?: string;
  professional_name?: string;
  professional_id?: string;
  professional_state?: string;
  type_id?: string;
  phone?: string;
  email?: string;
  link?: string;
  secret?: string;
  notes?: string;
}

export interface StatusReason {
  id: string;
  status: SickNoteStatus;
  threshold: string | number;
  value: string | number;
  description: string;
  public: boolean;
}

export interface ProcessingPayload {
  processing: boolean;
  status: SickNoteStatus;
}

export interface DocumentType {
  code: string;
  family: string;
  id: number;
  label: string;
  social_security: boolean;
}

export interface SickNoteType {
  children: SickNoteType;
  code: string;
  description: string;
  esocial_id: string;
  id: number;
  name: string;
  parent_id: number;
  visible: boolean;
  visible_b2c: boolean;
  document_type: DocumentType;
}

export interface SickNoteTypesResponse {
  total: number;
  num_pages: number;
  page: number;
  sick_notes_types: SickNoteType[];
}

export function listReasons(sickNote: IExtendedSickNote): string[] {
  const reasonsLabels: string[] = [];
  const publicNotes = sickNote?.fields?.public_notes;
  const availableReasons: any = REASONS;
  if (publicNotes?.message) {
    reasonsLabels.push(publicNotes.message);
  }
  if (publicNotes?.reasons) {
    const reasons = publicNotes.reasons
      .filter(r => !!availableReasons[r])
      ?.map(r => availableReasons[r]);
    reasonsLabels.push(...reasons);
  }
  if (sickNote.extras?.status_reasons) {
    const statusReasons = sickNote.extras.status_reasons
      .filter(s => s.status === sickNote.status && s.public)
      .map(s => s.description);
    reasonsLabels.push(...statusReasons);
  }
  return reasonsLabels;
}

export function getSickNoteIcon(status: SickNoteStatus): {svgIcon?: string; icon?: string} {
  switch (status) {
    case SickNoteStatus.TRIAGE:
      return {svgIcon: 'stethoscope'};
    case SickNoteStatus.INVALID:
      return {icon: 'thumb_down'};
    case SickNoteStatus.VALID:
      return {icon: 'thumb_up'};
    default:
      return {icon: 'schedule'};
  }
}

import packageInfo from '../../../package.json';

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyByk3eAu3IBeqnmQ9WGOM0BfqjAXf3KOjs',
    authDomain: 'dev-closecare2c.firebaseapp.com',
    databaseURL: 'https://dev-closecare2c.firebaseio.com',
    projectId: 'dev-closecare2c',
    storageBucket: 'dev-closecare2c.appspot.com',
    messagingSenderId: '407716069242',
    appId: '1:407716069242:web:9f085f65dc1462d4f860c3'
  },
  recaptcha_key: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  sentryDsn: 'https://27aaace71864425a81a7297f3d6f3899@o373977.ingest.sentry.io/5430093',
  closecareApiKey: 'AIzaSyAGmJUO2H91Sj2blXrez-yJogOZxaE_VSU',
  intercomAppId: 'pijdcech',
  version: packageInfo.version
};

export const AUTHENTICATED_DOMAINS = [
  'localhost',
  'closecare.com.br',
  'nustro-aaf4f.appspot.com',
  'firebasestorage.googleapis.com',
  'esp-apis-v3-ydd63pv53q-uc.a.run.app',
  'dev-api.closecare.cloud'
];

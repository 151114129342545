import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validator, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import IMask from 'imask';
import {LocalStorageService, STORAGE_KEYS} from '../../services/local-storage.service';
import {ChatService} from '../../services/chat.service';

function validatePhone(control: FormControl): null | {validatePhone: {valid: boolean}} {
  return !control.value?.length || control.value?.length === 15 || control.value?.length === 19 ? null : {
    validatePhone: {
      valid: false
    }
  };
}

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
  selector: 'app-validation.component',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit {
  public loading: boolean;
  public form: FormGroup;
  public phoneMask: IMask.AnyMaskedOptions = {
    mask: [
      {mask: '(00) 00000-0000'}
    ],
    signed: false,
    scale: 0,
  };

  private savedData: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private localStorage: LocalStorageService,
    private chatService: ChatService
  ) {}

  ngOnInit(): void {
    this.savedData = this.localStorage.get(STORAGE_KEYS.user_credentials) || {};
    this.form = this.formBuilder.group({
      phone: [this.savedData?.phone || '', [validatePhone]],
      email: [this.savedData?.email || '', [Validators.email, Validators.pattern(EMAIL_REGEX)]]
    });
  }

  public get formInvalid(): boolean {
    const value = this.form.value;
    const controls = this.form.controls;

    return (
      (!value.phone.length && !value.email.length) ||
      (controls.phone.invalid || this.form.controls.email.invalid)
    );
  }

  public next(): void {
    this.loading = true;
    this.chatService.data.phone = this.form.value.phone;
    this.chatService.data.email = this.form.value.email;
    this.saveCredentials();

    this.router.navigate(['/s/termos'])
      .then(() => this.loading = false);
  }

  private saveCredentials(): void {
    if (this.savedData.save_session) {
      this.savedData.email = this.form.value.email;
      this.savedData.phone = this.form.value.phone;
      this.localStorage.set(STORAGE_KEYS.user_credentials, this.savedData);
    }
  }
}

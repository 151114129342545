import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MessagesData } from '../models/messages';
import { ApiService, API_MESSAGES_LIST } from './api.service';

enum MessageType {
    CONFIRMATION_MESSAGE = 'confirmation_message',
}

@Injectable({
    providedIn: 'root'
})
export class MessagesService {
    constructor(private api: ApiService) { }

    getConfirmationMessage(status: string, documentTypeId: number): Observable<MessagesData> {
        let params = new HttpParams();
        params = params.set('documentStatus', status);
        params = params.set('messageType', MessageType.CONFIRMATION_MESSAGE);
        params = params.set('documentTypeId', `${documentTypeId}`);
        return this.api.http.get<MessagesData>(API_MESSAGES_LIST, { params });
    }
}

import {NgxLoggerLevel} from 'ngx-logger';
import * as Sentry from '@sentry/angular';
import {INGXLoggerMonitor} from 'ngx-logger/lib/monitor/ilogger-monitor';
import {INGXLoggerMetadata} from 'ngx-logger/lib/metadata/imetadata';

export class CustomLogMonitor implements INGXLoggerMonitor {
  onLog(log: INGXLoggerMetadata): void {
    if (log?.level >= NgxLoggerLevel.WARN) {
      Sentry?.captureMessage(log?.message);
    }
  }
}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';

import {ChatService} from '../services/chat.service';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalService {
  constructor(
    public router: Router,
    private chatService: ChatService
  ) {}

  resolve(): Observable<{[key: string]: string}> {
    return this.chatService.getProfessionalTypes()
      .pipe(take(1));
  }
}

<p>{{data?.bottomSheet?.description}}</p>
<div *ngFor="let field of data?.bottomSheet?.fields">
    <app-input-phone
        class="border-color-primary answer__field input__wrapper"
        fxLayoutAlign="stretch"
        *ngIf="field.type === answerType?.phone"
        [placeholder]="field.placeholder"
        [value]="field.value"
        (updated)="updated(answerType.phone, $event)"
    >
    </app-input-phone>
    <app-input-email
        class="border-color-primary answer__field input__wrapper"
        fxLayoutAlign="stretch"
        *ngIf="field.type === answerType?.email"
        [placeholder]="field.placeholder"
        [value]="field.value"
        (updated)="updated(answerType.email, $event)"
    >
    </app-input-email>
</div>
<app-button
    [button]="data?.bottomSheet?.button"
    [disabled]="fieldsInvalid"
    (click)="sendAnswer()"
>
</app-button>
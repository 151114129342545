export enum FileType {
  picture = 'picture',
  file = 'file'
}

export interface FileData {
  contentType: string;
  file?: File;
  fileUrl?: string;
}

export interface FileDialog {
  fileData: FileData;
  index?: number;
  okButton?: string;
  noButton?: string;
}

export interface FilePreviewResponse {
  label: string;
  value?: any;
}

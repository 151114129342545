<div *ngFor="let item of chat; let i = index">
  <ng-container *ngIf="(item.step === step && !typing) || item.step !== step">
    <ng-container [ngSwitch]="item.type">
      <div *ngSwitchCase="ChatItemEnum.ITEM_TYPE_QUESTION_TEXT" class="mb-24 question-container">
        <div class="little-face vertical-align-bottom mr-16"></div>
        <div
          class="
            chat-item
            question
            p-16
            border-radius-8
            box-shadow-strong
            mb-4
            font-size-14 font-weight-400
            vertical-align-bottom
          "
        >
          {{ item.value }}
        </div>
      </div>

      <div *ngSwitchCase="ChatItemEnum.ITEM_TYPE_QUESTION_HTML" class="mb-24 question-container">
        <div class="little-face vertical-align-bottom mr-16"></div>
        <div
          class="
            chat-item
            question
            p-16
            border-radius-8
            box-shadow-strong
            mb-4
            font-size-14 font-weight-400
            vertical-align-bottom
          "
          [innerHtml]="item.value | safeHtml"
        >
        </div>
      </div>

      <div *ngSwitchCase="ChatItemEnum.ITEM_TYPE_QUESTION_IMAGE" class="mb-24 question-container">
        <div class="little-face vertical-align-bottom mr-12"></div>
        <div
          class="
            chat-item
            question
            p-16
            border-radius-8
            box-shadow-strong
            mb-4
            vertical-align-bottom
          "
        >
          <img src="{{ item.value }}" />
        </div>
      </div>

      <div
        *ngSwitchCase="ChatItemEnum.ITEM_TYPE_ANSWER_TEXT"
        class="mb-24 text-align-right mr-8"
      >
        <button
          mat-button
          color="primary"
          type="button"
          aria-label="Editar"
          *ngIf="item.step === step - 1"
          (click)="undo()"
        >
          <mat-icon class="vertical-align-sub font-size-16">edit</mat-icon>
          Editar
        </button>

        <div
          class="
            chat-item
            answer
            p-16
            border-radius-8
            box-shadow-strong
            gradient-brand
            white-font
            vertical-align-middle
          "
        >
          {{ item.value }}
        </div>
      </div>

      <div
        *ngSwitchCase="ChatItemEnum.ITEM_TYPE_ANSWER_IMAGE"
        class="mb-24 text-align-right mr-8"
      >
        <button
          mat-button
          color="primary"
          type="button"
          aria-label="Editar"
          *ngIf="lastFile(i)"
          (click)="openFilePicker()"
        >
          <mat-icon class="vertical-align-sub font-size-16">edit</mat-icon>
          Editar
        </button>

        <div
          class="
            chat-item
            answer
            border-radius-8
            box-shadow-strong
            white-font
            vertical-align-middle
            answer-image-container
          "
        >
          <app-file-render
            class="answer-image"
            [fileData]="item.fileData"
            [imgCover]="true"
          ></app-file-render>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<div *ngIf="typing" class="mb-24">
  <div class="little-face mr-12"></div>
  <div class="chat-item p-16 border-radius-8 box-shadow-strong mb-4">
    <div class="bot-typing">
      <span class="typing-dots">
        <span class="typing-dot"></span>
        <span class="typing-dot"></span>
        <span class="typing-dot"></span>
      </span>
      Digitando
    </div>
  </div>
</div>

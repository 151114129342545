import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { DocTypeService } from '../services/doc-type.service';

@Injectable({
  providedIn: 'root'
})
export class DocTypeGuard implements CanActivate {
  constructor(
    private router: Router,
    private docTypeService: DocTypeService
  ) {}

  canActivate(): boolean | UrlTree {
    if (!this.docTypeService.selected) {
      return this.router.createUrlTree(['/c']);
    }

    return true;
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FlexModule,
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    FlexModule
  ]
})
export class SharedModule {}

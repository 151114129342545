import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import packageInfo from '../package.json';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {Integrations} from '@sentry/tracing';
import {Event} from '@sentry/types/dist/event';
import {Breadcrumb} from '@sentry/types/types/breadcrumb';

Sentry.init({
  dsn: environment.sentryDsn,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', '^https:\\/\\/.+\.web\.app', '^https:\\/\\/.+\.firebaseapp\.com', '^https:\/\/.+\.closecare\.com\.br'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  release: `${packageInfo.name}@${packageInfo.version}`,
  environment: environment.production ? 'production' : 'staging',
  tracesSampleRate: 0.3,
  beforeSend(event, hint): PromiseLike<Event | null> | Event | null {
    if (hint.originalException === 'Timeout') {
      return null;
    }
    return event;
  },
  beforeBreadcrumb(breadcrumb, hint): Breadcrumb | null {
    if (breadcrumb.category === 'ui.click') {
      const { target } = hint.event;
      if (target.ariaLabel) {
        breadcrumb.message = target.ariaLabel;
      }
    }
    return breadcrumb;
  }
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

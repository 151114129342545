import { SickNote, SickNoteFile, SickNoteSpecific } from './sick-note.entity';

export enum DocumentStatus {
  VALID = 'valid',
  INVALID = 'invalid',
  TRIAGE = 'triage',
  PENDING = 'pending',
  FILLED = 'filled',
  VERIFIED = 'verified',
  WARNING = 'warning',
}

/* DocumentContacts **************************************************************************************/
export class DocumentContacts {
  id: number;
  created_at: string;
  updated_at: string;
  email: string;
  phone: string;
}

/* DocumentFile **********************************************************************************/
export class DocumentFile {
  id: number;
  created_at: string;
  updated_at: string;
  bucket: string;
  content_type: string;
  deleted: boolean;
  is_clean: boolean;
  name: string;
  relative_path: string;
  signed_url: string;
}

/* DocumentOrganizationType **********************************************************************/
export class DocumentOrganizationType {
  id: number;
  created_at: string;
  updated_at: string;
  code: string;
  deleted: boolean;
  description: string;
  label: string;
  organization_id: number;
  slug: string;
  type: DocumentType;
  visible_b2b: boolean;
  visible_b2c: boolean;
}

export class DocumentType {
  id: number;
  created_at: string;
  updated_at: string;
  category: DocumentCategory;
  label: string;
  social_security: boolean;
}

export class DocumentCategory {
  id: number;
  created_at: string;
  updated_at: string;
  label: string;
  code: string;
}

/* DocumentReason ********************************************************************************/
export class DocumentReason {
  id: number;
  created_at: string;
  updated_at: string;
  label: string;
  reason_category: DocumentReasonCategory;
  registered_by_uid: string;
  report_label: string;
  updated_by_uid: string;
}

export class DocumentReasonCategory {
  id: number;
  created_at: string;
  updated_at: string;
  label: string;
  registered_by_uid: string;
  updated_by_uid: string;
}

/* DocumentSyncMessage ***************************************************************************/
export class DocumentSyncMessage {
  code: string;
  text: string;
}

/*************************************************************************************************/
/* Document                                                                                      */
/*************************************************************************************************/
export class DocumentBase {
  id: number;
  created_at: string;
  updated_at: string;

  automatic: boolean;
  contact: DocumentContacts;
  external_id: string;
  internal_notes: string;
  issue_date: string;
  organization_id: number;
  organization_type: DocumentOrganizationType;
  person_id: string;
  processed: boolean;
  public_notes: string;
  reasons: DocumentReason[];
  short_id: string;
  status: string;
  sync_message: DocumentSyncMessage;
  sync_status: string;
  team_id: number;
  verified: boolean;
}

export class Document extends DocumentBase {
  files: DocumentFile[];
  specific: OccupationalSpecific | SickNoteSpecific;
}

export class OccupationalSpecific {
  id: number;
  created_at: string;
  updated_at: string;
  coordinator_physician: CoordinatorPhysician;
  requested_medical_procedures: RequestedMedicalProcedure[];
  in_charge_physician: InChargePhysician;
  result: string;
}

export class CoordinatorPhysician {
  id: number;
  created_at: string;
  updated_at: string;
  code: number;
  cpf: string;
  name: string;
  organization_id: number;
  state: string;
}

export class RequestedMedicalProcedure {
  id: number;
  created_at: string;
  updated_at: string;
  medical_procedure: MedicalProcedure;
  issue_date: string;
}

export class MedicalProcedure {
  id: number;
  created_at: string;
  updated_at: string;
  code: string;
  label: string;
}

export class InChargePhysician {
  code: number;
  name: string;
  state: string;
}

export class DocumentList {
  items: DocumentBase[];
}

const mapSickNoteFileToDocumentFile = (
  sickNoteFile: SickNoteFile,
): DocumentFile => ({
    id: null,
    created_at: null,
    updated_at: null,
    bucket: null,
    content_type: null,
    deleted: null,
    is_clean: null,
    name: null,
    relative_path: null,
    signed_url: null,
  });

export const mapSickNoteApiResponseToDocument = (
  sickNote: SickNote,
): Document => ({
    automatic: null,
    contact: null,
    created_at: sickNote.extras.metadata.created_at,
    external_id: null,
    files: sickNote.fields.files.map((sickNoteFile) =>
      mapSickNoteFileToDocumentFile(sickNoteFile),
    ),
    id: null,
    internal_notes: null,
    issue_date: null,
    organization_id: null,
    organization_type: {
      id: null,
      created_at: null,
      updated_at: null,
      code: null,
      deleted: null,
      description: null,
      label: null,
      organization_id: null,
      slug: null,
      type: {
        id: sickNote.extras.type.id,
        created_at: null,
        updated_at: null,
        category: null,
        label: null,
        social_security: null,
      },
      visible_b2b: null,
      visible_b2c: null,
    },
    person_id: null,
    processed: null,
    public_notes: null,
    reasons: null,
    short_id: sickNote.short_id,
    specific: sickNote, // FIXME
    status: sickNote.status,
    sync_message: null,
    sync_status: null,
    team_id: null,
    updated_at: null,
    verified: null,
  });

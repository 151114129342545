<div fxLayout="column" fxLayoutAlign="space-between" fxFlex="auto">
    <form [formGroup]="form">
        <p class="description">Digite seu telefone ou e-mail para acompanhar seu atestado</p>
        <div>
            <mat-form-field appearance="standard">
                <mat-label>Celular</mat-label>
                <input matInput data-test="input-phone" formControlName="phone" name="phone" [imask]="phoneMask">
                <mat-error *ngIf="!form.controls.phone.valid && !form.controls.phone.pristine">Telefone inválido</mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>E-mail</mat-label>
                <input matInput data-test="input-email" formControlName="email" name="email">
                <mat-error *ngIf="!form.controls.email.valid && !form.controls.email.pristine">E-mail inválido</mat-error>
            </mat-form-field>
        </div>
    </form>
    <div>
        <button mat-raised-button data-test="submit" class="closecare-button primary-bg" (click)="next()"
        [disabled]="formInvalid" aria-label="Próximo">
        Próximo
        </button>
    </div>
</div>

<app-loading *ngIf="loading"></app-loading>

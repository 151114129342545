import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DateDiffPipe} from './date-diff.pipe';
import {SafeHtmlPipe} from './safe-html.pipe';
import { TypeListToStringPipe } from './type-list-to-string.pipe';



@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DateDiffPipe,
    SafeHtmlPipe,
    TypeListToStringPipe
  ],
  exports: [
    DateDiffPipe,
    SafeHtmlPipe,
    TypeListToStringPipe
  ]
})
export class PipesModule {}

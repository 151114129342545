import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {ChatService} from '../services/chat.service';

@Injectable({
  providedIn: 'root'
})
export class CodeGuard implements CanActivate {
  constructor(
    private router: Router,
    private chatService: ChatService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const data = this.chatService.data;

    if (!data.phone && !data.email) {
      this.router.navigate(['/s/validacao'], {state: {redirect: state.url}});
    }

    return true;
  }
}

import {AfterViewInit, Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {ActivatedRoute, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {NGXLogger} from 'ngx-logger';
import {combineLatest, interval, Observable, of} from 'rxjs';
import {catchError, filter, map, mergeMap, startWith, switchMap, takeWhile, tap} from 'rxjs/operators';

import {PickersBuilder} from './shared/pickers-builder';
import {KeysReplacer} from './shared/keys-replacer';
import {
  Answered,
  AnswerPickers,
  AnswerType,
  Branch,
  FlowType,
  Condition,
  From,
  Message,
  Prefix,
  Replacer,
  Replacers,
  Script,
  Send,
  ToLoad,
  BottomSheetTypes
} from './shared/models/chat.model';
import {FLOW, STARTERS, TIMER} from './shared/settings/chat.setting';
import {CompanySettings} from '../../models/company';
import {DosesPicker, StatesPicker} from '../../models/pickers.model';
import {ProfessionalCRM} from '../../models/professional';
import {ProcessingPayload} from '../../models/sick-note';
import {ChatService} from '../../services/chat.service';
import {DocTypeService} from '../../services/doc-type.service';
import {SickNoteService} from '../../services/sick-note.service';
import {API_SEND_SICK_NOTE_FILLED, API_SEND_SICK_NOTE_PENDING} from '../../services/api.service';
import {FileService} from '../../services/file.service';
import {FileSelectedDialogComponent} from '../../pages/file-selected/file-selected-dialog.component';
import {ItemPicker} from '../../components/item-picker/item-picker.model';
import {UserDataComponent} from './components/answers/corrections/user-data/user-data.component';
import {DoctorDataComponent} from './components/answers/corrections/doctor-data/doctor-data.component';
import {FileData} from 'src/app/models/file.model';

@UntilDestroy()
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  animations: [
    trigger('answer', [
      transition(':enter', [
        style({opacity: 0}),
        animate('200ms linear', style({opacity: 1}))
      ])
    ])
  ]
})

export class ChatComponent implements OnInit, AfterViewInit {
  public messages: Array<Message> = [];
  public from: any = From;
  public flowType: FlowType;
  public typing: boolean;
  public loading: boolean;
  public requestError: () => void;

  private lastAnswer: Answered;
  private invalidIcd: string;
  private professionalTypes: {[key: string]: string} = {};
  private vaccines: {[key: string]: string} = {};
  private docs: {[key: string]: string} = {};

  @ViewChild('scroller') private scroller: ElementRef;
  @ViewChildren('message') private messagesList: QueryList<HTMLElement>;
  @HostListener('window:resize', ['$event']) onResize(): void {
    this.moveScroll();
  }

  constructor(
    public chatService: ChatService,
    private matBottomSheet: MatBottomSheet,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private logger: NGXLogger,
    private keysReplacer: KeysReplacer,
    private pickersBuilder: PickersBuilder,
    private docTypeService: DocTypeService,
    private fileService: FileService,
    private sickNoteService: SickNoteService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data
      .pipe(untilDestroyed(this))
      .subscribe(({settings}: {settings: CompanySettings}) => {
        this.flowType = this.getBranch(settings);
      });

    this.rebuildData();
    this.chatService.buildData({
      reference: 'type_id',
      value: String(this.docTypeService.selected.id)
    });

    this.setBotMessage(this.startId);
  }

  ngAfterViewInit(): void {
    this.messagesList.changes
      .subscribe(() => this.messagesUpdated());

    this.fileService.answer
      .subscribe((data: FileData[]) => this.addMessagesWithFile(data));
  }

  public get message(): Message {
    for (let i = this.messages.length; i >= 0; --i) {
      if (this.messages[i]?.from === From.bot) {
        return this.messages[i];
      }
    }
  }

  public get startId(): string {
    if (this.chatService.data.phone && this.chatService.data.email) {
      return STARTERS.phoneAndEmail;
    }

    if (this.chatService.data.phone) {
      return STARTERS.phone;
    }

    return STARTERS.email;
  }

  public get script(): Script {
    return FLOW[this.message?.scriptId || 'start'];
  }

  public get edition(): number {
    for (let i = this.messages.length; i > 0; --i) {
      if (this.messages[i]?.from === From.user) {
        return i;
      }
    }

    return 0;
  }

  public get dependence(): string {
    return this.getData(this.script.answer?.reference?.dependence);
  }

  public get prefix(): any {
    const prefix: Prefix = this.script.answer.prefix;

    if (prefix) {
      const conditions: Array<Condition> = prefix.conditions;

      for (const condition of conditions) {
        if (this.getData(condition.key) !== condition.value) {
          return undefined;
        }
      }

      return prefix.fill;
    }
  }

  public get pickers(): Array<ItemPicker> {
    switch (this.script.answer?.picker) {
      case AnswerPickers.doses:
        return this.pickersBuilder.build(DosesPicker);
      case AnswerPickers.vaccineTypes:
        return this.pickersBuilder.build(this.vaccines);
      case AnswerPickers.professionalTitle:
        return this.pickersBuilder.build(this.professionalTypes);
      case AnswerPickers.states:
        return this.pickersBuilder.build(StatesPicker);
      case AnswerPickers.docTypes:
        return this.pickersBuilder.build(this.docs);
    }
  }

  public moveScroll(): void {
    if (this.scroller) {
      this.scroller.nativeElement.scrollTop = 99999999;
    }
  }

  public async sendAnswer(answered: Answered): Promise<void> {
    if (this.script.answer?.type === AnswerType.send) {
      return this.sendSickNoteData();
    }

    this.lastAnswer = answered;

    if (!!answered.bottomSheet) {
      if (answered.bottomSheet === BottomSheetTypes.fileType) {
        return this.fileType(true);
      }

      this.chatService.removeData(answered.cancel);
      return this.doctorCorrection(answered.bottomSheet);
    }

    if (!!answered.cancel) {
      this.chatService.removeData(answered.cancel);
    } else if (answered.send) {
      this.chatService.buildData(answered.send);
    }

    this.addMessage(From.user, answered.label || this.getValue(answered.send));
    this.setTyping();
  }

  public edit(index: number): void {
    if (!index) {
      return this.personalCorrection(FLOW[this.startId].nexts.edit);
    }

    const scriptToEdit = FLOW[this.messages[index].scriptId];

    if (!!scriptToEdit.onCancel) {
      if (scriptToEdit.onCancel.includes('file')) {
        const index: number = this.messages.length - this.fileService.length - 1;

        this.messages.splice(index, this.fileService.length + 1);
        this.fileType();
      } else {
        this.chatService.removeData(scriptToEdit.onCancel);
      }
    }

    this.typing = false;
    this.messages.splice(index, this.messages.length - index);
  }

  private getBranch (settings: CompanySettings): FlowType {
    if (this.docTypeService.selected.document_type.code === 'covid_vaccine') {
      this.chatService.allowanceHours = settings.covid_vax?.allowance_hours;

      if (!!this.chatService.allowanceHours) {
        if (this.chatService.allowanceHours < 24) {
          return Branch.timeAllowancedVaccine;
        } else {
          return Branch.dailyAllowancedVaccine;
        }
      } else {
        return Branch.noAllowancedVaccine;
      }
    } else {
      return settings.entry_mode === 'pending' ? Branch.basic : Branch.complete;
    }
  }

  private rebuildData(): void {
    const {phone, email} = this.chatService.data;

    this.chatService.resetData();
    this.chatService.data.phone = phone;
    this.chatService.data.email = email;
  }

  private getData(key: string): string {
    return this.chatService.data[key];
  }

  private setFlow(): void {
    if (this.lastAnswer?.cancel) {
      return this.setBotMessage(this.script.nexts.cancel as string);
    }

    if (this.invalidIcd) {
      return this.setBotMessage(this.script.nexts[this.invalidIcd]);
    }

    if (this.script.branch) {
      return this.setBotMessage(this.script.nexts[this.flowType]);
    }

    this.setBotMessage(this.getNextScriptId());
  }

  private getNextScriptId(): string {
    const lastValue: string = this.getValue(this.lastAnswer.send);
    const nextScript: string = this.script.nexts[lastValue];

    if (this.script.priority && nextScript) {
      return nextScript;
    }

    return (
      this.script.nexts.default ||
      this.script.nexts[
        lastValue ||
        this.flowType
      ] ||
      this.script.nexts.valid
    );
  }

  private setBotMessage(scriptId): void {
    if (scriptId === this.startId || this.typing) {
      this.typing = false;
      this.addMessage(
        From.bot,
        FLOW[scriptId]?.text,
        FLOW[scriptId]?.warning,
        FLOW[scriptId]?.image,
        null,
        scriptId
      );

      if (!this.script.answer) {
        this.setTyping();
      }
    }
  }

  private addMessagesWithFile(data: FileData[]): void {
    data.forEach((fileData: FileData) => {
      this.addMessage(
        From.user,
        null,
        null,
        null,
        fileData
      );
    });

    const files: File[] = data.map(({ file }) => file) as File[];

    this.sickNoteService.setFile(files);
    this.typing = true;
    this.setBotMessage(this.script.nexts.default);
  }

  private addMessage(
    from: From,
    body: string,
    warning?: boolean,
    image?: boolean,
    fileData?: FileData,
    scriptId: string = this.message.scriptId
  ): void {
    this.messages.push({from, body, image, warning, fileData});

    const message: Message = this.messages[this.messages.length - 1];

    if (FLOW[scriptId]) {
      message.scriptId = scriptId;
      message.replacers = this.getReplacers(scriptId);
      message.breakStrong = FLOW[scriptId].breakStrong;
    }
  }

  private getReplacers(scriptId: string): Replacers {
    const texts: Array<string> = [
      FLOW[scriptId].text,
      FLOW[scriptId].answer?.placeholder
    ];

    const replacers: Replacers = {};

    let keys: Array<string> = [];
    texts.forEach((text: string = '') => keys = keys.concat(text.match(/[$]\w+/g) || []));

    if (keys.length) {
      keys.forEach(($key: string) => {
        replacers[$key] = this.keysReplacer.replace(
          $key.substring(1),
          this.professionalTypes
        );
      });
    }

    return replacers;
  }

  private messagesUpdated(): void {
    const messages: NodeListOf<HTMLElement> = document.querySelectorAll('app-message');

    setTimeout(() => {
      const message: HTMLElement = messages[messages.length - 1];

      if (message) {
        const images: Array<HTMLImageElement> = Array.from(message.querySelectorAll('img'));

        if (images.length) {
          images.forEach((image: HTMLImageElement) => {
            if (message.classList.contains('image__loader')) {
              this.typing = true;
            }

            image.onload = () => {
              message.classList.add('image__loader--loaded');

              const interv = setInterval(() => {
                if (image.naturalWidth > 0 && image.naturalHeight > 0) {
                  clearInterval(interv);
                  this.typing = false;
                  this.moveScroll();
                }
              }, 20);
            };
          });
        }
      }

      this.moveScroll();
    });
  }

  private setTyping(): void {
    this.typing = true;

    let loadType: string = 'default';

    if (this.lastAnswer?.cancel || this.lastAnswer?.send) {
      loadType = 'cancel';
    } else if (this.lastAnswer?.send) {
      loadType = this.getValue(this.lastAnswer?.send)
    }

    const toLoad: string = this.script.load?.[loadType];

    if (toLoad === ToLoad.vaccineTypes) {
      return this.getVaccineTypes();
    }

    if (toLoad === ToLoad.profissionalTypes) {
      return this.getProfessionalTypes();
    }

    if (!!this.lastAnswer && !this.lastAnswer.cancel) {
      if (
        (
          this.script?.load?.default === ToLoad.professionals ||
          this.lastAnswer.adjusted
        ) &&
        this.chatService.data.professional_type === ProfessionalCRM
      ) {

        return this.getProfessionals();
      }

      if (toLoad === ToLoad.icds) {
        this.invalidIcd = this.hasInvalidIcd();

        if (!this.invalidIcd) {
          return this.validateICDs();
        }
      }
    }

    setTimeout(() => {
      if (this.typing) {
        this.setFlow();
      }
    }, Math.round(Math.random() * (TIMER.max - TIMER.min) + TIMER.min));
  }

  private hasInvalidIcd(): string {
    const icds: Array<string> = this.lastAnswer.label.split(', ');

    for (const i in icds) {
      if (this.script.nexts[icds[i]]) {
        return icds[i];
      }
    }
  }

  private getProfessionals(): void {
    this.setSnackbar();
    this.chatService.getProfessionals()
      .subscribe(
        (professionalName: string) => {
          if (this.typing) {
            this.chatService.buildData({
              value: professionalName,
              reference: Replacer.professional_name
            });
          }

          this.setBotMessage(this.script.nexts.CRM);
        },
        error => {
          if (error.status === 404) {
            this.setBotMessage(this.script.nexts.error);
          } else {
            this.setSnackbar(this.getProfessionals);
          }
        }
      );
  }

  private validateICDs(): void {
    this.setSnackbar();
    this.chatService.validateICDs()
      .subscribe(
        (valid: boolean) => this.setBotMessage(valid ? this.script.nexts.valid : this.script.nexts.invalid),
        () => this.setSnackbar(this.validateICDs)
      );
  }

  private getVaccineTypes(): void {
    this.setSnackbar();
    this.chatService.getVaccineTypes()
      .subscribe(
        (vaccines: {[key: string]: string}) => {
          this.vaccines = vaccines;
          this.setFlow();
        },
        () => this.setSnackbar(this.getVaccineTypes)
      );
  }

  private getProfessionalTypes(): void {
    this.setSnackbar();
    this.chatService.getProfessionalTypes()
      .subscribe(
        (professionalTypes: {[key: string]: string}) => {
          this.professionalTypes = professionalTypes;
          this.setFlow();
        },
        () => this.setSnackbar(this.getProfessionalTypes)
      );
  }

  private setSnackbar(method?: () => void): void {
    this.typing = !method;
    this.requestError = method;
  }

  private getValue(send: Send | Array<Send>): any {
    return Array.isArray(send) ? send[0]?.value : send?.value;
  }

  private personalCorrection(scriptId: string): void {
    FLOW[scriptId].answer.bottomSheet.fields[0].value = this.chatService.data.phone;
    FLOW[scriptId].answer.bottomSheet.fields[1].value = this.chatService.data.email;

    this.matBottomSheet.open(UserDataComponent, {
      panelClass: 'answer-bottom-sheet',
      data: {
        bottomSheet: FLOW[scriptId].answer.bottomSheet,
        replacers: this.message.replacers
      }
    }).afterDismissed()
      .subscribe((answered: Answered) => {
        if (answered) {
          this.chatService.buildData(answered.send);

          this.messages = [];
          this.setBotMessage(this.startId);
        }
      });
  }

  private doctorCorrection(bottomSheet: string): void {
    this.matBottomSheet.open(DoctorDataComponent, {
      panelClass: 'answer-bottom-sheet',
      data: {
        bottomSheet: FLOW[bottomSheet].answer.bottomSheet,
        replacers: this.message.replacers,
        states: this.pickersBuilder.build(StatesPicker)
      }
    }).afterDismissed()
      .subscribe((answered: Answered) => {
        if (answered) {
          this.sendAnswer(answered);
        }
      });
  }

  private fileType(showFileType?: boolean): void {
    this.fileService.showFileType = showFileType;

    FileSelectedDialogComponent
      .openModal(this.router, this.activatedRoute);
  }

  private waitProcessing(shortId: string): Observable<ProcessingPayload> {
    let runLimit = 4;
    return interval(3000)
      .pipe(
        tap(() => runLimit--),
        startWith(0),
        switchMap(() => {
          return this.sickNoteService.getSickNoteProcessingStatus(shortId)
            .pipe(catchError(() => of({processing: true, status: 'filled'} as ProcessingPayload)));
        }),
        takeWhile((res) => !!res?.processing && runLimit > 0, true),
        untilDestroyed(this),
        map((res) => {
          if (!res?.processing || runLimit <= 0) {
            return {
              processing: false,
              status: res?.status
            };
          }
          return res;
        })
      );
  }

  private sendSickNoteData(): void {
    this.loading = true;
    this.sickNoteService.setData(this.chatService.data);
    const endpoint = this.flowType === Branch.basic ? API_SEND_SICK_NOTE_PENDING : API_SEND_SICK_NOTE_FILLED;
    this.sickNoteService.sendSickNote(endpoint)
      .pipe(
        mergeMap((shortId) => combineLatest([of(shortId), this.waitProcessing(shortId)])),
        filter(([shortId, res]) => !res.processing)
      )
      .subscribe(
        ([shortId, res]) => {
          this.router.navigate(['/info', shortId], {replaceUrl: true});
        },
        error => this.logger.debug(error),
        () => this.loading = false
      );
  }
}

import {Component, Inject} from '@angular/core';
import {MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {BottomSheetData, AnswerType, Update, Answered, Answer, Send} from '../../../../shared/models/chat.model';

@Component({
  selector: 'app-answer-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./../corrections.scss']
})

export class UserDataComponent {
  public answerType: any = AnswerType;
  public fields: {[key: string]: Update} = {};

  constructor(
    private bottomSheetRef: MatBottomSheetRef<UserDataComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: BottomSheetData
  ) {}

  public get fieldsInvalid(): boolean {
    if (
      !this.fields?.email?.answered.label.length &&
      !this.fields?.phone?.answered.label.length
    ) {
      return true;
    }

    const fields: Array<Answer> = this.data?.bottomSheet?.fields;

    for (const i in fields) {
      if (
        !this.fields[fields[i].type]?.valid &&
        this.fields[fields[i].type]?.answered.label.length
      ) {
        return true;
      }
    }
  }

  public updated(type: string, event): void {
    this.fields[type] = event;
  }

  public sendAnswer(): void {
    this.bottomSheetRef.dismiss(this.buildAnswer());
  }


  private buildAnswer(): Answered {
    const answered: Answered = {cancel: [], send: []};

    this.data.bottomSheet.fields.forEach((field: Answer) => {
      const send: Array<Send> = answered.send as Array<Send>;

      send.push({
        value: (this.fields[field.type]?.answered.send as Send)?.value as string,
        reference: field.reference.key
      });
    });

    return answered;
  }
}

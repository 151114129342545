import {NgModule} from '@angular/core';
import {SharedModule} from '../../../../../../shared/shared.module';
import {ChatPipesModule} from '../../../../shared/pipes/pipes.module';
import {PhoneModule} from '../../inputs/phone/phone.module';
import {EmailModule} from './../../inputs/email/email.module';
import {ButtonModule} from './../../buttons/button/button.module';
import {UserDataComponent} from './user-data.component';


@NgModule({
  imports: [
    SharedModule,
    ChatPipesModule,
    PhoneModule,
    EmailModule,
    ButtonModule
  ],
  declarations: [
    UserDataComponent
  ],
  entryComponents: [
    UserDataComponent
  ],
  exports: [
    UserDataComponent
  ]
})

export class UserDataModule {}

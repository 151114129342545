import { Component, Input, OnDestroy, Optional, Self } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NgControl,
  Validators
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { ComponentEnum } from '../../dynamic-form.enums';
import { ComponentType } from '../../entities/dynamic-form.entity';

@Component({
  selector: 'app-input-wrapper',
  templateUrl: './input-wrapper.component.html',
  styleUrls: ['./input-wrapper.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputWrapperComponent,
    },
  ],
})
export class InputWrapperComponent
  implements OnDestroy, ControlValueAccessor, MatFormFieldControl<string> {
  @Input()
  currentComponent: ComponentType;

  @Input()
  set value(value: string) {
    this.formControl.setValue(value);
    this.stateChanges.next();
  }
  get value() {
    return this.formControl.value;
  }

  stateChanges = new Subject<void>();

  formControl: FormControl;

  id: string;
  focused: boolean;
  empty: boolean;
  shouldLabelFloat: boolean;
  required: boolean;
  disabled: boolean;
  placeholder: string;
  errorState: boolean;
  controlType?: string;
  autofilled?: boolean;
  userAriaDescribedBy?: string;

  onChange = (value: string) => {};
  onTouched = () => {};

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private fb: FormBuilder
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.formControl = new FormControl('', Validators.required);
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  writeValue(value: string): void {
    this.value = value;
  }
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  setDisabledState?(isDisabled: boolean): void {}
  setDescribedByIds(ids: string[]): void {}
  onContainerClick(event: MouseEvent): void {}

  get ComponentEnum() {
    return ComponentEnum;
  }

  done() {
    this.onChange(this.formControl.value);
  }
}

import {Component, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {FileDialog} from '../../models/file.model';
import {FileService} from '../../services/file.service';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview-dialog.component.html',
  styleUrls: ['./file-preview-dialog.component.css']
})

export class FilePreviewDialogComponent {
  fileDialog: FileDialog;
  fileSrc: string | Uint8Array;
  framed: boolean;

  @ViewChild('imageElement') imageElement: ElementRef;

  constructor(private fileService: FileService) {
    this.fileDialog = this.fileService.fileDialog;
  }

  static openModal(
    router: Router,
    route: ActivatedRoute
  ): Promise<boolean> {
    return router.navigate(
      ['', {outlets: {modal: 'visualizacao'}}],
      { relativeTo: route }
    );
  }

  redo(): void {
    this.fileService.showFileType = true;
    history.back();
  }

  confirm(): void {
    this.fileService.addFile(this.fileDialog);
    history.back();
  }
}

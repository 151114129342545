import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {Intercom} from 'ng-intercom';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {catchError, mergeMap} from 'rxjs/operators';
import {combineLatest, from, of} from 'rxjs';
import {environment} from '../../environments/environment';

export enum THEME {
  light = 'light',
  dark = 'dark'
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class SupportChatService {
  private _theme: THEME = THEME.light;
  public THEMES = THEME;

  constructor(private authService: AuthService,
              private intercom: Intercom) {}

  public init(): void {
    this.authService.user$.pipe(
      untilDestroyed(this),
      mergeMap(user => {
        if (!user) {
          return of([]);
        }
        const claims$ = from(user?.getIdTokenResult().then((tr) => tr?.claims as any));
        const userHash$ = this.authService.getUserHmacHash('intercom');
        return combineLatest([of(user.uid), claims$, userHash$]);
      }),
      catchError(() => {
        return [];
      })
    ).subscribe(([uid, claims, hash]) => {
      this.intercom.boot({
        app_id: environment.intercomAppId,
        user_id: uid || undefined,
        user_hash: hash,
        name: uid || undefined,
        app_name: 'Funcionario',
        app_version: environment.version,
        company: {
          id: claims?.company_id || undefined,
          name: claims?.company_id || undefined
        },
        hide_default_launcher: true,
        custom_launcher_selector: '#chatButton',
        widget: {
          activator: '#intercom'
        }
      });
    });
  }

  public setTheme(t: THEME): void {
    setTimeout(() => {
      this._theme = t;
    });
  }

  public get theme(): THEME {
    return this._theme;
  }
}

<ng-container *ngIf="currentComponent">
  <ng-container *ngFor="let option of currentComponent.options">
    <button
      class="font-size-16 border-radius-32 py-16 white-font gradient-brand"
      (click)="done(option)"
    >
      <mat-icon *ngIf="option.icon" class="mr-8">{{ option.icon }}</mat-icon>
      {{ option.label }}
    </button>
  </ng-container>
</ng-container>

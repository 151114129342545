import {Injectable} from '@angular/core';
import {getHours, getMinutes, subDays, format} from 'date-fns';
import {Replacer} from './models/chat.model';
import {StatesPicker} from '../../../models/pickers.model';

import {ChatService} from '../../../services/chat.service';

export interface Dates {
  apt: any;
  exp: any;
}

@Injectable()
export class KeysReplacer {
  constructor(private chatService: ChatService) {}

  public replace(key: string, values?: {[key: string]: string}): string {
    switch (key) {
      case Replacer.phone:
        return this.chatService.data.phone;
      case Replacer.email:
        return this.chatService.data.email;
      case Replacer.period_date:
        return this.builPeriod();
      case Replacer.invalid_icd:
        return this.buildICDs();
      case Replacer.professional_type:
        return this.chatService.data.professional_type;
      case Replacer.professional_title:
        return values[this.chatService.data.professional_type];
      case Replacer.professional_id:
        return this.chatService.data.professional_id;
      case Replacer.professional_state:
        return StatesPicker[this.chatService.data.professional_state];
      case Replacer.professional_name:
          return this.buildProfessionalNames();
      case Replacer.allowance_days:
        return this.buildAllowanceDays();
      case Replacer.allowance_period:
        return this.buildAllowancePeriod();
      case Replacer.allowance_hours:
        return this.buildAllowanceHours();
    }
  }

  private get allowanceDays (): number {
    return Math.trunc(this.chatService.allowanceHours / 24);
  }

  private builPeriod(): string {
    const dates: Dates = {
      apt: new Date(this.chatService.data.appointment_date),
      exp: new Date(this.chatService.data.expiration_date)
    };

    if (
      getHours(dates.apt) !== getHours(dates.exp) ||
      getMinutes(dates.apt) !== getMinutes(dates.exp)
    ) {
      return this.buildPeriodHours(dates);
    }

    return this.buildPeriodDays(dates);
  }

  private buildPeriodHours(dates: Dates): string {
    const dateApt = dates.apt.toLocaleDateString('pt-BR');
    const timeApt = dates.apt.toLocaleTimeString('pt-BR').substring(0, 5);
    const dateExp = dates.exp.toLocaleDateString('pt-BR');
    const timeExp = dates.exp.toLocaleTimeString('pt-BR').substring(0, 5);

    if (dateApt === dateExp) {
      return `no dia <strong>${dateApt}, das ${timeApt} às ${timeExp}</strong>`;
    }

    return `do dia <strong>${dateApt} às ${timeApt} até ${dateExp} às ${timeExp}</strong>`;
  }

  private buildPeriodDays(dates: Dates): string {
    const dateApt: Date = dates.apt;
    const dateExp: Date = subDays(dates.exp, 1);

    let dateAptNew: string = dateApt.toLocaleDateString('pt-BR');

    if (dateApt.toLocaleDateString() === dateExp.toLocaleDateString()) {
      return `no dia <strong>${dateAptNew}</strong>`;
    }

    dateAptNew = dateAptNew.substring(0, 5);
    const dateExpNew: string = dateExp.toLocaleDateString('pt-BR');

    return `entre os dias <strong>${dateAptNew} e ${dateExpNew}</strong>`;
  }

  private buildICDs(): string {
    const icds: string = this.chatService.invalidICDs.join(', ');

    if (this.chatService.invalidICDs.length > 1) {
      return `CIDs ${icds} inválidos`;
    }

    return `CID ${icds} inválido`;
  }

  private buildProfessionalNames(): string {
    if (this.chatService.data.professional_name) {
      const words: string[] = this.chatService.data.professional_name.split(' ');

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].toLowerCase();

        if (words[i] !== 'da' && words[i] !== 'de') {
          words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }
      }

      return `<strong>${words.join(' ')}</strong>`;
    }
  }

  private buildAllowanceDays(): string {
    return `${this.allowanceDays} dia${this.allowanceDays > 1 ? 's' : ''}`;
  }

  private buildAllowancePeriod (): string {
    const appointment: string = this.getDate(this.chatService.data.appointment_date);
    const expiration: string = this.getDate(this.chatService.data.expiration_date);

    if (this.allowanceDays === 1) {
      return `no dia ${appointment}`;
    } else {
      return `entre o dia ${appointment} até o dia ${expiration}`;
    }
  }

  private getDate (date: string): string {
    return format(new Date(date), 'dd/MM/yyyy');
  }

  private buildAllowanceHours(): string {
    const hours: number = this.chatService.allowanceHours;

    return `${hours} hora${hours === 1 ? '' : 's'}`;
  }
}

import {Component} from '@angular/core';
import {Location} from '@angular/common';
import {TitleService} from '../../services/title.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent {
  constructor(
    public titleService: TitleService,
    private location: Location
  ) {}

  back(): void {
    this.location.back();
  }
}

<div class="domain-wrapper" [ngClass]="{ 'test': companyType === 'test' }">
  <div class="test-announcement">
    <strong>Você está em uma versão de testes. <a href="https://atestado.me">Ir para o site real</a></strong>
  </div>
  <mat-sidenav-container (backdropClick)="sidenav.close()">
    <mat-sidenav-content>
      <div class="main-content">
        <button
          mat-stroked-button
          id="chatButton"
          color="primary"
          class="closecare-button"
          aria-label="Ajuda"
          *ngIf="renderChatButton"
          [class.dark]="supportChatService.theme === supportChatService.THEMES.dark"
        >
        Ajuda
        </button>
        <router-outlet></router-outlet>
        <router-outlet name="modal"></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <app-loading *ngIf="loading$ | async"></app-loading>
</div>

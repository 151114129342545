<pdf-viewer
  class="pdf-preview"
  *ngIf="fileData.contentType === 'application/pdf'; else showImage"
  [src]="fileSrc"
  [render-text]="false"
  [original-size]="false"
  [show-all]="false"
  [page]="1"
  [zoom]="1"
  [zoom-scale]="'page-width'"
  (after-load-complete)="loading = false"
>
</pdf-viewer>
<ng-template #showImage>
  <div
    class="file-render__image"
    fxFlex="100%"
    fxLayoutAlign="center"
    #imageElement
    [ngClass]="{ 'file-render__image--cover': imgCover}"
  ></div>
</ng-template>
<app-spinner *ngIf="loading"></app-spinner>

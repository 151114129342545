import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';

import * as Sentry from '@sentry/angular';
import * as loadImage from 'blueimp-load-image';
import { NGXLogger } from 'ngx-logger';

import { FileData } from '../../models/file.model';
import { FileService } from '../../services/file.service';

@Component({
  selector: 'app-file-render',
  templateUrl: './file-render.component.html',
  styleUrls: ['./file-render.component.scss']
})

export class FileRenderComponent implements AfterViewInit {
  loading = true;
  fileSrc: string | Uint8Array;

  @Input() imgCover: boolean;
  @Input() fileData: FileData;

  @ViewChild('imageElement') imageElement: ElementRef;

  constructor(
    private renderer: Renderer2,
    private logger: NGXLogger,
    private fileService: FileService
  ) {
    (window as any).pdfWorkerSrc = '/assets/js/pdf.worker.min.js';
  }

  ngAfterViewInit(): void {
    this.fileData.fileUrl ? this.getFile() : this.buildPreview();
  }


  private getFile(): void {
    this.fileService.getBlob(this.fileData.fileUrl)
      .subscribe(
        blob => this.fileLoaded(blob),
        error => this.logger.error(error)
      );
  }

  private fileLoaded(blob: Blob): void {
    this.fileData.file = new File([blob], 'no-name');
    this.buildPreview();
  }

  private buildPreview(): void {
    if (this.fileData.contentType === 'application/pdf') {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        if (typeof reader.result === 'string') {
          this.fileSrc = reader.result;
        } else {
          this.fileSrc = new Uint8Array(reader.result);
        }
      });
      reader.readAsArrayBuffer(this.fileData.file);

    } else if (this.fileData.contentType?.includes('image/')) {
      loadImage(this.fileData.file, {maxWidth: 1200, orientation: true, meta: true})
        .then(({ image }) => {
          this.renderer.appendChild(this.imageElement.nativeElement, image);
          this.loading = false;
          this.fileSrc = '';
        })
        .catch((e) => {
          this.loading = false;
          console.error(`Error loading image: ${this.fileData.contentType}`);
          Sentry.captureException(e);
        });
    } else {
      Sentry.captureMessage(`File type not accepted: ${this.fileData.contentType}`);
      // TODO type not accepted
    }
  }
}

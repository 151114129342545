import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Button, Answered} from '../../../../shared/models/chat.model';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})

export class ButtonComponent {
  @Input() public button: Button;
  @Input() public disabled: boolean;
  @Input() public reference: string;
  @Input() public cancel: Array<string>;
  @Output() private answered: EventEmitter<Answered> = new EventEmitter<Answered>();

  public setAnswer(value: string = this.button.correction): void {
    this.answered.emit(this.buildAnswer(value));
  }

  private buildAnswer(value: string): Answered {
    const ansered: Answered = {
      label: this.button.label
    };

    if (value) {
      ansered.send = {
        value,
        reference: this.reference
      };
    }

    if (!value || this.button.forceCancel) {
      ansered.cancel = this.cancel;
    }

    if (this.button.correction) {
      ansered.bottomSheet = this.button.correction;
      ansered.cancel = this.cancel;
    }

    return ansered;
  }
}

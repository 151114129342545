<input
    required
    matInput
    data-test="input-email"
    class="input__field"
    autocomplete="email"
    inputmode="email"
    type="email"
    name="email"
    [placeholder]="placeholder"
    [formControl]="email"
>
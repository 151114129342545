<div #modal class="file__modal" fxLayout="column">
  <mat-toolbar>
    <button mat-icon-button (click)="back()" color="primary" aria-label="Voltar">
      <mat-icon fontSet="material-icons-outlined">arrow_back_ios</mat-icon>
    </button>
  </mat-toolbar>
  <div class="overflow-container" fxFlex="1 1 auto" fxLayout="column">
    <div class="mat-headline">
      Arquivos e fotos
    </div>
    <p>Atenção! Não adicione arquivos de documents diferentes. Apenas um documento é aceito por cadastro.</p>
    <div class="file-selected__buttons" fxFlex="1 1 auto">
      <div class="file-selected__scroll">
        <div
          class="file-selected__file"
          *ngFor="let file of files | async; let i = index"
        >
          <button
            mat-button
            class="closecare-button primary-bg file-selected__button"
            (click)="showFileType(i)"
          >
            <div class="file-selected__preview">
              <app-file-render
                class="file-render"
                [fileData]="file"
                [imgCover]="true"
              ></app-file-render>
            </div>
          </button>
          <button
            mat-stroked-button
            color="primary"
            class="file-selected__remove"
            (click)="removeFile(i)"
          >
          </button>
        </div>
        <div class="file-selected__file">
          <button
            mat-button
            class="closecare-button primary-bg file-selected__button"
            (click)="showFileType()"
          >
            <div class="file-selected__preview">
              <div class="file-selected__add"></div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="file-selected__confirmation" *ngIf="!!(files | async).length">
    <button
      mat-raised-button
      color="primary"
      class="closecare-button primary-bg"
      aria-label="Continuar"
      (click)="continue()"
    >
      Continuar
    </button>
  </div>
</div>

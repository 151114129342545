<mat-chip-list #chips class="chips">
    <mat-chip *ngFor="let icd of icds; index as i" removable [selectable]="false" (removed)="remove(i)">
        {{icd}}
        <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
        required
        matInput
        upperCase
        data-test="input-icd"
        class="input__field input__field--icds"
        autocomplete="off"
        [placeholder]="placeholder"
        [imask]="icdMask"
        [matChipInputFor]="chips"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [formControl]="icd"
        (matChipInputTokenEnd)="add($event)"
    >
</mat-chip-list>
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {SharedModule} from '../../../../../../shared/shared.module';
import {DialogTimeModule} from './dialog-time/dialog-time.module';
import {TimeComponent} from './time.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    MatButtonModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    SharedModule,
    DialogTimeModule
  ],
  declarations: [
    TimeComponent
  ],
  entryComponents: [
    TimeComponent
  ],
  exports: [
    TimeComponent
  ]
})

export class TimeModule {}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SharedModule} from '../../../../shared/shared.module';
import {ChatPipesModule} from '../../shared/pipes/pipes.module';
import {EditModule} from '../../../../components/edit/edit.module';
import {FileRenderModule} from '../../../../components/file-render/file-render.module';
import {MessageComponent} from './message.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ChatPipesModule,
    EditModule,
    FileRenderModule
  ],
  declarations: [
    MessageComponent
  ],
  exports: [
    MessageComponent
  ],
  entryComponents: [
    MessageComponent
  ]
})

export class MessageModule {}

import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {formatISO, addDays} from 'date-fns';
import IMask from 'imask';
import {Update} from '../../../../shared/models/chat.model';

@Component({
  selector: 'app-input-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss']
})

export class CodeComponent implements OnInit {
  public code: FormControl = new FormControl('', Validators.required);
  public codeMask: IMask.AnyMaskedOptions = {
    mask: '00000000000000000000',
    signed: false,
    scale: 0,
  };

  @Input() public placeholder: string;
  @Input() public reference: string;
  @Input() public prefix: number;
  @Output() private updated: EventEmitter<Update> = new EventEmitter<Update>();

  ngOnInit(): void {
    this.code.valueChanges
      .subscribe((value: string) => this.updated.emit(this.buildAnswer(value)));
  }

  private buildAnswer(label: string): Update {
    let value: string = label;

    if (this.prefix) {
      label = `${this.prefix}-${label}`;
    }

    return {
      valid: this.code.valid,
      answered: {
        label,
        send: {
          value,
          reference: this.reference
        }
      }
    };
  }
}

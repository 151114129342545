import {Component, ViewEncapsulation, Input, Output, EventEmitter} from '@angular/core';
import {FileData} from 'src/app/models/file.model';

import {From} from '../../shared/models/chat.model';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MessageComponent {
  @Input() public from: string;
  @Input() public warning: boolean;
  @Input() public edition: boolean;
  @Input() public body: string;
  @Input() public fileData: FileData;
  @Input() public breakStrong: boolean;
  @Output() public edit: EventEmitter<any> = new EventEmitter<any>();

  public get fromUser(): boolean {
    return this.from === From.user;
  }
}

import { format, formatISO } from 'date-fns';
import { FileData } from 'src/app/models/file.model';
import { fileToDataUrl } from 'src/app/shared/utils';
import { FileType, OptionType } from './entities/dynamic-form.entity';

export type ChatViewType = string | FileData[];

abstract class GenericAnswer<T, U> {
  protected toChatView: ChatViewType;
  protected toFormsApi: string;
  protected toDocumentsApi: U;
  constructor(object: T) {
    this.setToChatView(object);
    this.setToFormsApi(object);
    this.setToDocumentsApi(object);
  }
  protected abstract setToChatView(object: T): void;
  protected abstract setToFormsApi(object: T): void;
  protected abstract setToDocumentsApi(object: T): void;
}

export class InputTextAnswer extends GenericAnswer<string, string> {
  constructor(object: string) {
    super(object);
  }
  protected setToChatView(object: string) {
    this.toChatView = object;
  }
  protected setToFormsApi(object: string) {
    this.toFormsApi = object;
  }
  protected setToDocumentsApi(object: string) {
    this.toDocumentsApi = object;
  }
}
export class InputDateAnswer extends GenericAnswer<Date, string> {
  constructor(object: Date) {
    super(object);
  }
  protected setToChatView(object: Date) {
    this.toChatView = format(object, 'dd/MM/yyyy');
  }
  protected setToFormsApi(object: Date) {
    this.toFormsApi = formatISO(object);
  }
  protected setToDocumentsApi(object: Date) {
    this.toDocumentsApi = formatISO(object);
  }
}

export class TwoButtonsAnswer extends GenericAnswer<OptionType, string> {
  constructor(object: OptionType) {
    super(object);
  }
  protected setToChatView(object: OptionType) {
    this.toChatView = object.label;
  }
  protected setToFormsApi(object: OptionType) {
    this.toFormsApi = object.value;
  }
  protected setToDocumentsApi(object: OptionType) {
    this.toDocumentsApi = object.value;
  }
}

export class FilePickerAnswer extends GenericAnswer<FileData[], FileType[]> {
  constructor(object: FileData[]) {
    super(object);
  }
  protected setToChatView(object: FileData[]) {
    this.toChatView = object;
  }
  protected setToFormsApi(object: FileData[]) {
    this.toFormsApi = object.reduce(
      (accumulator, currentValue) =>
        accumulator === ''
          ? currentValue.file.name
          : accumulator + ',' + currentValue.file.name,
      ''
    );
  }
  protected setToDocumentsApi(object: FileData[]) {
    Promise.all(object.map(async (fileData) => {
      const dataUrl = await fileToDataUrl(fileData.file);
      const base64 = dataUrl.split(',')[1];
      // the split above is safe, since commas will not appear on the data itself
      // https://developer.mozilla.org/en-US/docs/Glossary/Base64
      const { type } = fileData.file;
      const fileType = {
        content_base64: base64,
        content_type: type,
      };
      return fileType;
    })).then((result) => this.toDocumentsApi = result);
  }
}

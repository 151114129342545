import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {IMaskModule} from 'angular-imask';
import {SharedModule} from '../../shared/shared.module';
import {LoadingModule} from '../../components/loading/loading.module';
import {ValidationComponent} from './validation.component';


@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    IMaskModule,
    SharedModule,
    LoadingModule,
  ],
  declarations: [
    ValidationComponent
  ],
})
export class PersonalDataModule {}

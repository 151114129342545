import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, Subject, BehaviorSubject} from 'rxjs';

import {FileDialog, FileData} from '../models/file.model';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  showFileType: boolean;
  answer = new Subject<FileData[]>();
  fileDialog: FileDialog;

  private _data = new BehaviorSubject<FileData[]>([]);

  constructor(private http: HttpClient) { }

  get data$(): Observable<FileData[]> {
    return this._data;
  }

  get length(): number {
    return this.data.length;
  }

  private get data(): FileData[] {
    return this._data.getValue();
  }

  getBlob(fileUrl: string): Observable<Blob> {
    return this.http.get(fileUrl, { responseType: 'blob' });
  }

  addFile({index, fileData}: FileDialog): void {
    this.data[index] = fileData;
    this._data.next(this.data);
  }

  removeFile(index: number): void {
    this.data.splice(index, 1);
    this._data.next(this.data);
  }

  resetFiles(): void {
    this._data.next([]);
  }

  setAnswer() {
    this.answer.next(this.data);
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {NgxUpperCaseDirectiveModule} from 'ngx-upper-case-directive';
import {IMaskModule} from 'angular-imask';
import {ICDComponent} from './icd.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule,
    NgxUpperCaseDirectiveModule,
    IMaskModule
  ],
  declarations: [
    ICDComponent
  ],
  entryComponents: [
    ICDComponent
  ],
  exports: [
    ICDComponent
  ]
})

export class ICDModule {}

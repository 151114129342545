<div class="document-selection-container">
    <div class="document-types-container p-32">
        <div class="document-types">
            <div class="document-types-title font-weight-400 mb-32">
                Selecione o tipo do documento de {{ selectedCategory?.label | lowercase }}
            </div>

            <mat-radio-group aria-label="" [formControl]="formControl">
                <mat-radio-button *ngFor="let type of selectedCategory?.types" [value]="type" class="mb-24">
                    {{ type.label }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <button
            class="full-width font-size-16 font-weight-400 border-radius-24 py-16"
            [ngClass]="disabled ? 'button-invalid' : 'button-valid'"
            [disabled]="disabled"
            (click)="next()">
            Próximo
        </button>
    </div>
</div>

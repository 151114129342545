import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {SharedModule} from '../../../../../../shared/shared.module';
import {ButtonComponent} from './button.component';


@NgModule({
  imports: [
    MatButtonModule,
    SharedModule
  ],
  declarations: [
    ButtonComponent
  ],
  entryComponents: [
    ButtonComponent
  ],
  exports: [
    ButtonComponent
  ]
})

export class ButtonModule {}

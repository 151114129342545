<div class="main-logo">
  <img src="assets/atestado_me.png" alt="atestado.me">
</div>
<div class="container">
  <div class="mat-headline">Cadastre seus atestados</div>
  <button mat-raised-button data-test="next" class="closecare-button primary-bg" routerLink="/s"
          aria-label="Próximo">
    Próximo
  </button>
</div>
<div class="poweredby">
  <span>feito por </span><img src="assets/closecare.png" alt="closecare">
</div>

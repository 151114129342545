<div class="border-color-primary">
  <div class="answer__field">
    <app-button-date
      *ngIf="answer.type === answerType.date"
      [answer]="answer"
      [flowType]="flowType"
      [allowanceHours]="allowanceHours"
      (answered)="sendAnswer.emit($event)"
    >
    </app-button-date>
    <app-button-time
      *ngIf="answer.type === answerType.time"
      [answer]="answer"
      [dependence]="dependence"
      [flowType]="flowType"
      [allowanceHours]="allowanceHours"
      (answered)="sendAnswer.emit($event)"
    >
    </app-button-time>
    <app-button-picker
      *ngIf="answer.type === answerType.picker"
      [answer]="answer"
      [pickers]="pickers"
      (answered)="sendAnswer.emit($event)"
    >
    </app-button-picker>
    <div class="answer__buttons" *ngIf="common" [ngClass]="{ 'answer__buttons--vertical': answer.vertical }">
      <app-button
          class="answer__buttons--wrapper"
          *ngFor="let button of answer.buttons"
          [button]="button"
          [reference]="answer.reference?.key"
          [cancel]="cancel"
          (answered)="sendAnswer.emit($event)"
      >
      </app-button>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocTypeGuard } from 'src/app/guards/doc-type.guard';

import { AuthorizedGuard } from './../../guards/authorized.guard';
import { CodeGuard } from './../../guards/code.guard';
import { TermsAcceptedGuard } from './../../guards/terms_accepted.guard';
import { EntryModeResolverService } from './../../resolvers/entry-mode-resolver.service';
import { FilePreviewDialogComponent } from './../file-preview/file-preview-dialog.component';
import { FileSelectedDialogComponent } from './../file-selected/file-selected-dialog.component';
import { ChatComponent } from './chat.component';

const routes: Routes = [
  {
    path: '',
    component: ChatComponent,
    resolve: {settings: EntryModeResolverService},
    canActivate: [AuthorizedGuard, CodeGuard, TermsAcceptedGuard, DocTypeGuard]
  }, {
    path: 'arquivos',
    component: FileSelectedDialogComponent,
    outlet: 'modal',
    canActivate: [AuthorizedGuard, CodeGuard, TermsAcceptedGuard, DocTypeGuard]
  }, {
    path: 'visualizacao',
    component: FilePreviewDialogComponent,
    outlet: 'modal',
    canActivate: [AuthorizedGuard, CodeGuard, TermsAcceptedGuard, DocTypeGuard]
  },
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class ChatRoutingModule {}

import { ClipboardModule } from '@angular/cdk/clipboard';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import {
  AngularFireAnalyticsModule,
  APP_NAME,
  APP_VERSION,
  ScreenTrackingService,
  UserTrackingService
} from '@angular/fire/compat/analytics';
import { AngularFireAuthModule, SETTINGS } from '@angular/fire/compat/auth';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';
import {
  AngularFireRemoteConfigModule,
  DEFAULTS
} from '@angular/fire/compat/remote-config';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import * as Sentry from '@sentry/angular';
import { IntercomModule } from 'ng-intercom';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { DesignTokensComponent } from 'src/stories/design-tokens.component';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EditModule } from './components/edit/edit.module';
import { ItemPickerModule } from './components/item-picker/item-picker.module';
import { LoadingModule } from './components/loading/loading.module';
import { SimpleDialogModule } from './components/simple-dialog/simple-dialog.module';
import { SpinnerModule } from './components/spinner/spinner.module';
import { BaseComponent } from './pages/base/base.component';
import { ChatModule } from './pages/chat/chat.module';
import { DocTypeModule } from './pages/doc-type/doc-type.module';
import { DocumentCategoriesModule } from './pages/document-categories/document-categories.module';
import { DocumentTypesModule } from './pages/document-types/document-types.module';
import { DynamicFormModule } from './pages/dynamic-form/dynamic-form.module';
import { HomeComponent } from './pages/home/home.component';
import { InformationsComponent } from './pages/informations/informations.component';
import { LoginModule } from './pages/login/login.module';
import { SickNoteModule } from './pages/sick-note/sick-note.module';
import { TermsModule } from './pages/terms/terms.module';
import { PersonalDataModule } from './pages/validation/validation.module';
import { AuthInterceptor } from './services/auth-interceptor.service';
import { CustomDateAdapter } from './shared/custom-date-adapter';
import { SentryErrorHandler } from './shared/sentry-error-handler';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localePt, 'pt-BR');

const LOG_LEVEL: NgxLoggerLevel = environment.production
  ? NgxLoggerLevel.WARN
  : NgxLoggerLevel.DEBUG;

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BaseComponent,
    InformationsComponent,
    DesignTokensComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
    AppRoutingModule,
    MatToolbarModule,
    MatButtonModule,
    HttpClientModule,
    LoggerModule.forRoot({
      level: LOG_LEVEL,
      serverLogLevel: NgxLoggerLevel.OFF,
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    AngularFirePerformanceModule,
    AngularFireRemoteConfigModule,
    MatIconModule,
    MatProgressSpinnerModule,
    SickNoteModule,
    DynamicFormModule,
    ChatModule,
    LoginModule,
    PersonalDataModule,
    LoadingModule,
    EditModule,
    SimpleDialogModule,
    ItemPickerModule,
    SpinnerModule,
    IntercomModule.forRoot({
      appId: environment.intercomAppId,
      updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
    }),
    TermsModule,
    DocTypeModule,
    ClipboardModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatDividerModule,
    MatListModule,
    DocumentCategoriesModule,
    DocumentTypesModule,
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    { provide: APP_NAME, useValue: environment.firebase.projectId },
    { provide: APP_VERSION, useValue: environment.version },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
    { provide: DEFAULTS, useValue: { dynamicForms: false } },
    {
      provide: SETTINGS,
      useFactory: () =>
        environment.production ? {} : { minimumFetchIntervalMillis: 0 },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
